import Vue from 'vue'
import Home from '@/views/Home.vue'
import Router from 'vue-router'

// Vue.use(Router)
//防止路由重复报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//路由
export const constantRouterMap = [
    //首页
    {
        path: '/',
        component: Home,
        redirect: '/',
        children: [
            // 二级路由首页
            {
                id: '1',
                path: "/",
                component: () => import('@/views/home/AppMain.vue'),
                children: [
                    // 首页
                    {
                        id: '4',
                        path: "/",
                        name: "首页",
                        component: () => import('@/views/home/homePage/Index.vue')
                    },
                    //登录日志
                    {
                        id: '5',
                        path: "/loginLog",
                        name: "登录日志",
                        component: () => import('@/views/home/homePage/LoginLog.vue')
                    },
                    //操作日志
                    {
                        id: '6',
                        path: "/operationLog",
                        name: "操作日志",
                        component: () => import('@/views/home/homePage/OperationLog.vue')
                    },
                    //数据看板
                    {
                        id: '0',
                        path: "/software",
                        name: "数据看板",
                        component: () => import('@/views/home/homePage/Software.vue')
                    },
                ]
            },
            //管理中心
            {
                id: '2',
                path: "/management",
                component: () => import('@/views/management/AppMain.vue'),
                redirect: '/reservation',
                children: [
                    //商家管理
                    {
                        id: '7',
                        path: "/reservation",
                        name: "商家管理",
                        component: () => import('@/views/management/managementPage/reservation/Reservation.vue')
                    },
                    //新增商家
                    {
                        id: '7',
                        path: "/appointmentInfo",
                        name: "新增商家",
                        component: () => import('@/views/management/managementPage/reservation/AppointmentInfo.vue')
                    },
                    //商家审核
                    {
                        id: '7',
                        path: "/toExamine",
                        name: "商家审核",
                        component: () => import('@/views/management/managementPage/reservation/ToExamine.vue')
                    },
                    //商家管理详情
                    {
                        id: '7',
                        path: "/reservationDetail",
                        component: () => import('@/views/management/managementPage/reservation/ReservationDetail.vue'),
                        redirect: '/basicInfo',
                        children: [
                            //基本资料
                            {
                                path: "/basicInfo",
                                name: "基本资料",
                                component: () => import('@/views/management/managementPage/reservation/child/BasicInfo.vue')
                            },
                            //续费记录
                            {
                                path: "/clothingInfo",
                                name: "续费记录",
                                component: () => import('@/views/management/managementPage/reservation/child/ClothingInfo.vue')
                            },
                            //充值记录
                            {
                                path: "/charging",
                                name: "充值记录",
                                component: () => import('@/views/management/managementPage/reservation/child/Charging.vue')
                            },
                        ]
                    },
                    //广告管理
                    {
                        id: '26',
                        path: "/advertisement",
                        name: "广告管理",
                        component: () => import('@/views/management/managementPage/advertisement/Advertisement.vue')
                    },
                    //提现管理
                    {
                        id: '0',
                        path: "/withdrawal",
                        name: "提现管理",
                        component: () => import('@/views/management/managementPage/withdrawal/Withdrawal.vue')
                    },
                    //模特管理
                    {
                        id: '17',
                        path: "/modelManagement",
                        name: "模特管理",
                        component: () => import('@/views/management/managementPage/modelManagement/ModelManagement.vue')
                    },
                    //功能授权
                    {
                        id: '30',
                        path: "/authorization",
                        name: "功能授权",
                        component: () => import('@/views/management/managementPage/authorization/Authorization.vue')
                    },
                    //子账号管理
                    {
                        id: '34',
                        path: "/subAccount",
                        name: "子账号管理",
                        component: () => import('@/views/management/managementPage/subAccount/SubAccount.vue')
                    },
                    //数据字典
                    {
                        id: '39',
                        path: "/dataDictionary",
                        name: "数据字典",
                        component: () => import('@/views/management/managementPage/dataDictionary/DataDictionary.vue')
                    },
                    //模特协议
                    {
                        id: '0',
                        path: "/modelAgreement",
                        name: "模特协议",
                        component: () => import('@/views/management/managementPage/modelAgreement/ModelAgreement.vue')
                    }
                ]
            },
            // 图库管理
            {
                id: '3',
                path: "/library",
                component: () => import('@/views/library/AppMain.vue'),
                redirect: '/librarylist',
                children: [
                    // 图库列表
                    {
                        id: '43',
                        path: "/librarylist",
                        name: "图库列表",
                        component: () => import('@/views/library/libraryPage/librarylist/librarylist.vue')
                    },
                    // 图库详情
                    {
                        id: '43',
                        path: "/libraryDetail",
                        name: "图库详情",
                        component: () => import('@/views/library/libraryPage/librarylist/libraryDetail.vue')
                    },
                    // 添加图库
                    {
                        id: '43',
                        path: "/addlibrary",
                        name: "添加图库",
                        component: () => import('@/views/library/libraryPage/librarylist/addlibrary.vue')
                    },
                    //图库审核
                    {
                        id: '44',
                        path: "/libraryreview",
                        name: "图库审核",
                        component: () => import('@/views/library/libraryPage/libraryreview/libraryreview.vue')
                    },
                    //图库审核详情
                    {
                        id: '44',
                        path: "/libraryreviewDetail",
                        name: "图库审核详情",
                        component: () => import('@/views/library/libraryPage/libraryreview/libraryreviewDetail.vue')
                    },
                    //拍摄主题
                    {
                        id: '45',
                        path: "/librarytheme",
                        name: "商品主题",
                        component: () => import('@/views/library/libraryPage/librarytheme/librarytheme.vue')
                    },
                    //图库字典
                    {
                        id: '46',
                        path: "/librarydictionary",
                        name: "图库字典",
                        component: () => import('@/views/library/libraryPage/librarydictionary/librarydictionary.vue')
                    },
                    //名师推荐
                    {
                        id: '47',
                        path: "/teacherrecommend",
                        name: "名师推荐",
                        component: () => import('@/views/library/libraryPage/teacherrecommend/teacherrecommend.vue')
                    },
                    //订单管理
                    {
                        id: '24',
                        path: "/order",
                        name: "订单管理",
                        component: () => import('@/views/library/libraryPage/order/Order.vue')
                    },
                    //订单详情
                    {
                        id: '24',
                        path: "/orderDetail",
                        name: "订单详情",
                        component: () => import('@/views/library/libraryPage/order/orderDetail.vue')
                    },
                    //充值管理
                    {
                        id: '25',
                        path: "/recharge",
                        name: "充值管理",
                        component: () => import('@/views/library/libraryPage/recharge/Recharge.vue')
                    },
                ]
            }
        ]
    },
    //登录
    {
        path: "/login",
        name: "login",
        component: () => import('@/views/login/Login.vue')
    },
    //登录
    {
        path: "/forgotPassword",
        component: () => import('@/views/login/ForgotPassword.vue')
    }
]

// 路由鉴权
if (localStorage.getItem("role_id")) {
    if (localStorage.getItem("role_id") != 1) {
        if (localStorage.getItem('menu_ids')) {
            let menu_ids = localStorage.getItem('menu_ids');
            menu_ids = JSON.parse(menu_ids);
            constantRouterMap[0].children = constantRouterMap[0].children.filter(element => {
                return menu_ids.includes(element.id)
            })
            constantRouterMap[0].children.forEach(item => {
                item.children = item.children.filter(element => {
                    return menu_ids.includes(element.id)
                })
            })
            constantRouterMap[0].redirect =
                constantRouterMap[0].children[0].children[0].path
        }
    }
}

const router = new Router({
    routes: constantRouterMap
})
router.beforeEach(async (to, from, next) => {
    let token = localStorage.getItem('token');
    // console.log(!token&&to.name!== 'login');

    // let arr = ['/login', '/forgotPassword']
    // if (arr.indexOf(to.name) != -1 && !token) {
    //     next({ name: 'login' })
    // } else {
    //     next()
    // }

    // console.log(constantRouterMap);
    if (to.name == "login") {
        next()
    } else if (to.name == null) {
        next()
    } else {
        if (!token) {
            next({ name: 'login' })
        } else {
            // next()

            // console.log(to.name);
            constantRouterMap.forEach(item => {
                if (item.name == to.name) {
                    // console.log(to.name);
                    next()
                }
                if (item.children) {
                    item.children.forEach(element => {
                        if (element.name == to.name) {
                            // console.log(to.name);
                            next()
                        }
                        if (element.children) {
                            element.children.forEach(evt => {
                                if (evt.name == to.name) {
                                    // console.log(to.name);
                                    next()
                                }

                                if (evt.children) {
                                    evt.children.forEach(e => {
                                        if (e.name == to.name) {
                                            // console.log(to.name);
                                            next()
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }
    }
})
export default router