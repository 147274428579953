import Vue from 'vue'
//引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入vue-router
import router from './router/index'  //刚刚建立的文件夹
import VueRouter from 'vue-router'
import App from './App.vue'


//引入组件
import Component from '@/utils/index.js'
let filDate = (e) => {
  if (e == 0 || e == null) {
    return null
  }
  let d = new Date(e * 1000);
  let year = d.getFullYear();
  let month = d.getMonth();
  month = month + 1 > 12 ? 1 : month + 1;
  month = month > 9 ? month : "0" + month.toString();
  let day = d.getDate();
  day = day > 9 ? day : "0" + day.toString();
  let hour = d.getHours();
  hour = hour > 9 ? hour : "0" + hour.toString();
  let minute = d.getMinutes();
  minute = minute > 9 ? minute : "0" + minute.toString();
  let second = d.getSeconds();
  second = second > 9 ? second : "0" + second.toString();
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}
Vue.prototype.filDate = filDate;
Vue.prototype.stime = (date) => {
  let y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? ('0' + m) : m
  let d = date.getDate()
  d = d < 10 ? ('0' + d) : d
  let h = date.getHours()
  h = h < 10 ? ('0' + h) : h
  let M = date.getMinutes()
  M = M < 10 ? ('0' + M) : M
  let s = date.getSeconds()
  s = s < 10 ? ('0' + s) : s
  let dateTime = y + '-' + m + '-' + d + ' ' + h + ':' + M + ':' + s;
  return dateTime;

};
Vue.prototype.imgurl = "https://jiekou.paiyide.cc";
Vue.prototype.uploadimg = "https://jiekou.paiyide.cc/api/platform/index/uploadimg"
Vue.prototype.uploadfile = "https://jiekou.paiyide.cc/api/platform/index/uploadfile"

// 年/月/日 时/分/秒 时间过滤
Vue.filter('filDate', filDate)
//引入API
import API from '@/request/api';
Vue.prototype.$api = API;

// 引入vuex


//使用element-ui
Vue.use(ElementUI);
//使用vue-router
Vue.use(VueRouter)
//全局注册组件
Vue.use(Component)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
