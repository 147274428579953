<template>
    <!-- 拍摄完成/拍摄未完成 -->
    <div v-if="data.flag" class="mask">
        <div class="mask-main">
            <div class="mask-header">
                <div class="mask-left">
                    服装列表
                </div>
            </div>
            <div class="mask-line"></div>
            <div class="mask-table">
                <div class="btn-foot" v-if="data.type == 'see'">
                    <button class="btn-color" @click="handleSupplies('add')">关联物资</button>
                    <button>批量移除</button>
                </div>
                <div v-if="data.type == 'add'" class="search_nav">
                    <!-- 搜索框 -->
                    <el-input v-model="search_input" class="search_inp" style="width: 13.542vw;"
                        placeholder="物资ID/物资名称/创建人"></el-input>
                    <!-- 衣码下拉框 -->
                    <el-select v-model="value" placeholder="请选择库位编号" class="clothing_size" clearable>
                        <el-option v-for="item in clothing_size" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <!-- 评价状态下拉框 -->
                    <el-select v-model="value" placeholder="请选择物资分类" class="evaluation_status" clearable>
                        <el-option v-for="item in evaluation_status" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <div class="block" style="margin-right: 0.521vw;">
                        <el-date-picker v-model="value1" type="daterange" range-separator="-" start-placeholder="创建时间开始"
                            end-placeholder="创建时间结束">
                        </el-date-picker>
                    </div>
                    <!-- 查询按钮 -->
                    <el-button class="searchBtn" type="primary">查询</el-button>
                </div>
                <el-table id="filei" class="center_table" max-height="500" ref="multipleTable" :data="data.arr"
                    tooltip-effect="dark" style="width: 100%" border :header-cell-style="{
                        width: 81.823 + 'vw',
                        height: 5.185 + 'vh',
                        background: '#F9F9F9',
                        fontSize: 0.833 + 'vw',
                        textAlign: 'center',

                    }" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" min-width="4.8px" align="center">
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="物资名称" min-width="14.8px">
                        <template slot-scope="scope">
                            <span class="aFu_span">{{ scope.row.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="locator_id" align="center" label="库位编号" show-overflow-tooltip
                        min-width="16px"></el-table-column>
                    <el-table-column align="center" prop="counts" label="数量" show-overflow-tooltip min-width="9px">
                    </el-table-column>
                    <el-table-column align="center" prop="images" label="物资实物" show-overflow-tooltip
                        min-width="20.9px"></el-table-column>
                    <el-table-column align="center" prop="cate" label="物资分类" show-overflow-tooltip
                        min-width="11.9px"></el-table-column>
                    <el-table-column align="center" prop="sex" label="适用性别" show-overflow-tooltip
                        min-width="11.9px"></el-table-column>
                    <el-table-column align="center" prop="zt2" label="关联记录" show-overflow-tooltip min-width="12.2px">
                        <template slot-scope="scope">
                            <span class="aFu_span">查看</span>
                        </template></el-table-column>
                    <el-table-column align="center" prop="createtime" label="创建时间" show-overflow-tooltip
                        min-width="27.9px"></el-table-column>
                    <el-table-column label="创建人" prop="updatetime" align="center" min-width="12px">
                    </el-table-column>
                </el-table>
            </div>
            <div class="mask-foot">
                <button @click="handleClose" v-if="data.type == 'see'" class="btn-color">保存</button>
                <button @click="handleClose" v-else class="btn-color">确认关联</button>
                <button @click="handleClose">取消</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "capture",
    props: ['data'],
    data() {
        return {
            importArr: [
                {
                    id: 1,
                    date_id: "BZTK00001",
                    name: "绿叶仙踪快乐男孩",
                    association: "查看物资",
                    zt: "",
                    ps: "未拍摄",
                    cp: "否",
                    zt2: "儿童照",
                    age: "百天/满月/1周岁..."

                }, {
                    id: 2,
                    date_id: "BZTK00001",
                    name: "绿叶仙踪快乐男孩",
                    association: "查看物资",
                    zt: "",
                    ps: "未拍摄",
                    cp: "否",
                    zt2: "儿童照",
                    age: "百天/满月/1周岁..."

                }, {
                    id: 3,
                    date_id: "BZTK00001",
                    name: "绿叶仙踪快乐男孩",
                    association: "查看物资",
                    zt: "",
                    ps: "未拍摄",
                    cp: "否",
                    zt2: "儿童照",
                    age: "百天/满月/1周岁..."

                }
            ],
            multipleSelection: [],
        }
    },
    mounted() { },
    methods: {
        handleSupplies(type) {
            this.data.type = type;
        },
        //关闭弹窗
        handleClose() {
            let obj = {
                flag: false
            }
            // console.log(obj);
            this.$emit('handleSuppliesCell', obj)
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
    },
    created() {
        console.log(this.data.arr)
    }

}
</script>

<style lang="less" scoped>
@import url("@/assets/css/maskCap.less");
@import url("@/assets/css/reservation/telement.less");

.filei .btn-foot {
    justify-content: flex-end;
    margin-top: 0;
    margin-bottom: 2.222vh;
}

.search_nav {
    padding: 0 0 2.222vh;
}

.search_nav .search_inp {
    margin-left: 0;
}

.btn-foot {
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
    margin-bottom: 2.222vh;

    button {
        width: 6.25vw;
        height: 4.259vh;
        background-color: #ffffff;
        border-radius: 0.521vw;
        border: solid 0.052vw #2c72ff;
        color: #2c72ff;
        margin-right: 1.563vw;
        cursor: pointer;
    }

    button:last-child {
        margin-right: 0;
    }

    .btn-color {
        background-color: #2c72ff;
        color: #fff;
    }
}
</style>