<template>
  <div class="order-mask" v-if="negative.negativeFlag">
    <!-- 上传底片 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <div></div>
          {{ negative.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="table">
        <el-input type="textarea" placeholder="请输入内容" v-model="negative.textarea">
        </el-input>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "FailureReason",
  props: ["negative"],
  data() {
    return {
      textarea: "",
    };
  },
  updated() {
    console.log(this.negative);
    this.textarea = this.negative.content;
  },
  methods: {
    //取消弹窗
    handleRefund() {
      let obj = {
        negativeFlag: false
      }
      this.$emit("negativeCellDis", obj);
    },
    handleSubmit() {
      let obj = {
        negativeFlag: false,
        typeText: this.textarea
      }
      this.$emit("negativeCellDis", obj);
    },
  },
}
</script>
    
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/.table {
  padding-top: 1.852vh;

  textarea {
    height: 33.333vh;
    resize: none;
  }
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fff;
  cursor: pointer;
}
</style>