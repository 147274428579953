import { render, staticRenderFns } from "./ViewMaterial.vue?vue&type=template&id=c073b732&scoped=true"
import script from "./ViewMaterial.vue?vue&type=script&lang=js"
export * from "./ViewMaterial.vue?vue&type=script&lang=js"
import style0 from "./ViewMaterial.vue?vue&type=style&index=0&id=c073b732&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c073b732",
  null
  
)

export default component.exports