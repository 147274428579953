import { post, get, request } from "./http.js";

//接口案例
export const getCouponDetail = (data) => post("/coupondetail", data);

// 登录接口
const indexLogin = (data) => post("index/login", data);
//生成带参数小程序码
const indexSetqrcode = (data) => post("index/setqrcode", data);
//上传图片
const uploadimg = (data) => post("index/uploadimg", data);
//上传文件
const uploadfile = (data) => post("index/uploadfile", data);
//登录日志
const loginlog = (data) => post("index/loginlog", data);
//操作日志
const operatelog = (data) => post("index/operatelog", data);
// 获取当前账号信息
const indexgetInfo = (data) => post("index/getinfo", data);
// 首页
const indexIndex = (data) => post("index/index", data);

//客户管理
//客户列表
const storeLst = (data) => post("store/lst", data);
//新增、编辑
const storeEdit = (data) => post("store/edit", data);
//客户详情
const storeDetail = (data) => post("store/detail", data);
//客户审核
const storeShenhe = (data) => post("store/shenhe", data);
//批量设置软件服务费
const storeSetsoftfee = (data) => post("store/setsoftfee", data);
//批量或单个禁用\启用
const storeSetstatus = (data) => post("store/setstatus", data);
//单个、批量删除
const storeDel = (data) => post("store/del", data);
//得到商家端后台所有功能菜单
const storeGetallmenu = (data) => post("store/getallmenu", data);
//催缴
const storeRemindpay = (data) => post("store/remindpay", data);
// 批量导入图库
const storeImport = (data) => post("store/import", data);
// 获取图库批量导入记录
const storeGetloglist = (data) => post("store/getloglist", data);
// 商家公众号设置 appid、appsecret参数
const storeWxset = (data) => post("store/wxset", data);
// 得到小程序二维码
const storeGetimage = (data) => post("store/getimage", data);
// 得到商家模板消息配置数据
const storeTempget = (data) => post("store/tempget", data);
// 设置商家消息模板
const storeTempset = (data) => post("store/tempset", data);
// 配置ERP对接数据
const storeSeterpconfig = (data) => post("store/seterpconfig", data);
// 得到ERP对接数据
const storeGeterpconfig = (data) => post("store/geterpconfig", data);
// 上传顾客绑定关联链接二维码
const storeUploadbindqrcode = (data) => post("store/uploadbindqrcode", data);

//广告管理
//广告列表
const adLst = (data) => post("ad/lst", data);
//新增、编辑
const adEdit = (data) => post("ad/edit", data);
//批量、单个删除
const adDel = (data) => post("ad/del", data);
//批量、单个启用 禁用
const adSetstatus = (data) => post("ad/setstatus", data);
//得到广告类型
const adGettype = (data) => post("ad/gettype", data);

//模特管理
//模特列表
const modelerLst = (data) => post("modeler/lst", data);
//新增、编辑信息
const modelerEdit = (data) => post("modeler/edit", data);
//批量、单个删除
const modelerDel = (data) => post("modeler/del", data);
//批量、单个启用 禁用
const modelerSetstatus = (data) => post("modeler/setstatus", data);
//发送协议 微信公众号模板消息
const modelerSendprotocol = (data) => post("modeler/sendprotocol", data);
//模特协议 新增 编辑
const modelerEditprotocol = (data) => post("modeler/editprotocol", data);
//得到模特协议详情
const modelerGetprotocol = (data) => post("modeler/getprotocol", data);
//得到模特协议修改记录
const modelerGetlog = (data) => post("modeler/getlog", data);
//得到服务人员
const modelerGetadmin = (data) => post("modeler/getadmin", data);

//子账号管理
//子账号管理列表
const adminLst = (data) => post("admin/lst", data);
//新增、编辑信息
const adminEdit = (data) => post("admin/edit", data);
//批量、单个删除
const adminDel = (data) => post("admin/del", data);
//批量、单个启用 禁用
const adminSetstatus = (data) => post("admin/setstatus", data);
//得到可选角色
const adminGetrole = (data) => post("admin/getrole", data);

// 数据字典
// 数据字典参数名列表
const dictionaryParamlist = (data) => post("dictionary/paramlist", data);
// 参数值列表
const dictionaryValuelist = (data) => post("dictionary/valuelist", data);
// 新增编辑参数值信息
const dictionaryEditvalue = (data) => post("dictionary/editvalue", data);
// 批量、单个删除
const dictionaryDel = (data) => post("dictionary/del", data);
// 批量、单个启用 禁用
const dictionarySetstatus = (data) => post("dictionary/setstatus", data);

//功能授权
//角色对应权限菜单
const authorizeLst = (data) => post("authorize/lst", data);
//所有的系统权限
const authorizeGetallmenu = (data) => post("authorize/getallmenu", data);
//新增、编辑角色
const authorizeEdit = (data) => post("authorize/edit", data);
//删除角色
const authorizeDel = (data) => post("authorize/del", data);
//给相应角色分配菜单权限
const authorizeSetmenu = (data) => post("authorize/setmenu", data);
//重新绑定
const adminRebind = (data) => post("admin/rebind", data);

// 图库管理
// 图库列表
const imagegoodsLst = (data) => post("imagegoods/lst", data);
// 新增编辑图库
const imagegoodsEdit = (data) => post("imagegoods/edit", data);
// 图库详情
const imagegoodsDetail = (data) => post("imagegoods/detail", data);
// 图库删除
const imagegoodsDel = (data) => post("imagegoods/del", data);
// 图库批量、单个上架、下架
const imagegoodsSetstatus = (data) => post("imagegoods/setstatus", data);
// 新增、编辑道具链接
const imagegoodsEditlink = (data) => post("imagegoods/editlink", data);
// 删除道具链接
const imagegoodsDellink = (data) => post("imagegoods/dellink", data);
// 批量导入道具链接
const imagegoodsImportlink = (data) => post("imagegoods/importlink", data);
// 得到衣码
const imagegoodsGetsize = (data) => post("imagegoods/getsize", data);
// 得到商品属性
const imagegoodsGettype = (data) => post("imagegoods/gettype", data);
// 得到排序
const imagegoodsGetorder = (data) => post("imagegoods/getorder", data);
// 得到适用年龄
const imagegoodsGetage = (data) => post("imagegoods/getage", data);
// 图库审核列表
const imagegoodsExaminelist = (data) => post("imagegoods/examinelist", data);
// 得到客户（店铺）列表
const imagegoodsStorelist = (data) => post("imagegoods/storelist", data);
// 单个、批量审核图库详情
const imagegoodsExaminedetail = (data) => post("imagegoods/examinedetail", data);
// 单个审核、批量提交审核 审核通过
const imagegoodsSetexaminepass = (data) => post("imagegoods/setexaminepass", data);
// 单个审核、批量提交审核 审核不通过
const imagegoodsSetexaminerefuse = (data) => post("imagegoods/setexaminerefuse", data);

// 图库拍摄主题
// 列表
const imagethemeLst = (data) => post("imagetheme/lst", data);
// 新增编辑
const imagethemeEdit = (data) => post("imagetheme/edit", data);
// 批量导入
const imagethemeImport = (data) => post("imagetheme/import", data);
// 批量、单个删除
const imagethemeDel = (data) => post("imagetheme/del", data);
// 批量、单个启用 禁用
const imagethemeSetstatus = (data) => post("imagetheme/setstatus", data);

// 图库字典
// 参数名列表
const imagedictionaryParamlist = (data) => post("imagedictionary/paramlist", data);
// 参数值列表
const imagedictionaryValuelist = (data) => post("imagedictionary/valuelist", data);
// 新增编辑参数值信息
const imagedictionaryEditvalue = (data) => post("imagedictionary/editvalue", data);
// 批量、单个删除
const imagedictionaryDel = (data) => post("imagedictionary/del", data);
// 批量、单个启用 禁用
const imagedictionarySetstatus = (data) => post("imagedictionary/setstatus", data);

// 图库名师推荐
// 列表
const imageteacherLst = (data) => post("imageteacher/lst", data);
// 新增编辑
const imageteacherEdit = (data) => post("imageteacher/edit", data);
// 批量、单个删除
const imageteacherDel = (data) => post("imageteacher/del", data);
// 批量、单个推荐 不推荐
const imageteacherSetstatus = (data) => post("imageteacher/setstatus", data);
// 得到摄影类型
const imageteacherGetcapturetype = (data) => post("imageteacher/getcapturetype", data);
// 得到摄影风格
const imageteacherGetcapturestyle = (data) => post("imageteacher/getcapturestyle", data);
// 得到作品风格
const imageteacherGetworksstyle = (data) => post("imageteacher/getworksstyle", data);

// 充值管理
// 充值记录列表
const rechargeRechargelog = (data) => post("recharge/rechargelog", data);
// 充值金额列表
const rechargeMoneylist = (data) => post("recharge/moneylist", data);
// 设置充值金额
const rechargeMoneyedit = (data) => post("recharge/moneyedit", data);
// 清空删除充值金额数据
const rechargeMoneydel = (data) => post("recharge/moneydel", data);

// 订单管理
// 列表
const imageorderLst = (data) => post("imageorder/lst", data);
// 详情
const imageorderDetail = (data) => post("imageorder/detail", data);


export default {
    indexLogin,
    indexSetqrcode,
    uploadimg,
    uploadfile,
    loginlog,
    operatelog,
    indexgetInfo,
    indexIndex,

    storeLst,
    storeEdit,
    storeDetail,
    storeShenhe,
    storeSetsoftfee,
    storeSetstatus,
    storeDel,
    storeGetallmenu,
    storeRemindpay,
    storeImport,
    storeGetloglist,
    storeWxset,
    storeGetimage,
    storeTempget,
    storeTempset,
    storeSeterpconfig,
    storeGeterpconfig,
    storeUploadbindqrcode,

    adLst,
    adEdit,
    adDel,
    adSetstatus,
    adGettype,

    modelerLst,
    modelerEdit,
    modelerDel,
    modelerSetstatus,
    modelerSendprotocol,
    modelerEditprotocol,
    modelerGetprotocol,
    modelerGetlog,
    modelerGetadmin,

    adminLst,
    adminEdit,
    adminDel,
    adminSetstatus,
    adminGetrole,

    dictionaryParamlist,
    dictionaryValuelist,
    dictionaryEditvalue,
    dictionaryDel,
    dictionarySetstatus,

    authorizeLst,
    authorizeGetallmenu,
    authorizeEdit,
    authorizeDel,
    authorizeSetmenu,
    adminRebind,

    imagegoodsLst,
    imagegoodsEdit,
    imagegoodsDetail,
    imagegoodsDel,
    imagegoodsSetstatus,
    imagegoodsEditlink,
    imagegoodsDellink,
    imagegoodsImportlink,
    imagegoodsGetsize,
    imagegoodsGettype,
    imagegoodsGetorder,
    imagegoodsGetage,
    imagegoodsExaminelist,
    imagegoodsStorelist,
    imagegoodsExaminedetail,
    imagegoodsSetexaminepass,
    imagegoodsSetexaminerefuse,

    imagethemeLst,
    imagethemeEdit,
    imagethemeImport,
    imagethemeDel,
    imagethemeSetstatus,

    imagedictionaryParamlist,
    imagedictionaryValuelist,
    imagedictionaryEditvalue,
    imagedictionaryDel,
    imagedictionarySetstatus,

    imageteacherLst,
    imageteacherEdit,
    imageteacherDel,
    imageteacherSetstatus,
    imageteacherGetcapturetype,
    imageteacherGetcapturestyle,
    imageteacherGetworksstyle,

    rechargeRechargelog,
    rechargeMoneylist,
    rechargeMoneyedit,
    rechargeMoneydel,

    imageorderLst,
    imageorderDetail,















}