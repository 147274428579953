<template>
    <!-- 重拍 -->
    <div v-if="data.flag" class="mask">
        <div class="mask-main">
            <div class="mask-header">
                <div class="mask-left">
                    {{ data.title }}
                </div>
                <i @click="handleClose" class="el-icon-close posi"></i>
            </div>
            <div class="mask-line"></div>
            <div class="mask-table">
                <div class="mask-lang">服装列表</div>
                <el-table class="center_table" max-height="500" ref="multipleTable" :data="importArr" tooltip-effect="dark"
                    style="width: 100%" border :header-cell-style="{
                        width: 72.9699 + 'vw',
                        height: 5.185 + 'vh',
                        background: '#F9F9F9',
                        fontSize: 0.833 + 'vw',
                        textAlign: 'center',

                    }">
                    <el-table-column align="center" prop="date_id" label="物资ID" min-width="18.6px">
                        <template slot-scope="scope">
                            <span class="aFu_span">{{ scope.row.date_id }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" align="center" label="物资名称" show-overflow-tooltip
                        min-width="16.7px"></el-table-column>
                    <el-table-column align="center" prop="zt" label="库位编号" show-overflow-tooltip
                        min-width="12.5px"></el-table-column>
                    <el-table-column align="center" prop="ps" label="数量" show-overflow-tooltip
                        min-width="9.7px"></el-table-column>
                    <el-table-column align="center" prop="cp" label="物资实物" show-overflow-tooltip
                        min-width="23.7px"></el-table-column>
                    <el-table-column align="center" prop="zt2" label="物资分类" show-overflow-tooltip
                        min-width="8.8px"></el-table-column>
                    <el-table-column align="center" prop="zt2" label="适用性别" show-overflow-tooltip
                        min-width="8.9px"></el-table-column>
                    <el-table-column align="center" prop="zt2" label="创建时间" show-overflow-tooltip
                        min-width="15.8px"></el-table-column>
                    <el-table-column align="center" prop="zt2" label="创建人" show-overflow-tooltip
                        min-width="15.8px"></el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
//上传底片
import FailReason from '@/components/management/FailReason.vue';
export default {
    name: "substancesCell",
    props: ['data'],
    data() {
        return {
            importArr: [
                {
                    id: 1,
                    date_id: "BZTK00001",
                    name: "绿叶仙踪快乐男孩",
                    association: "查看物资",
                    zt: "",
                    ps: "未拍摄",
                    cp: "否",
                    zt2: "儿童照",
                    age: "百天/满月/1周岁..."

                }, {
                    id: 2,
                    date_id: "BZTK00001",
                    name: "绿叶仙踪快乐男孩",
                    association: "查看物资",
                    zt: "",
                    ps: "未拍摄",
                    cp: "否",
                    zt2: "儿童照",
                    age: "百天/满月/1周岁..."

                }, {
                    id: 3,
                    date_id: "BZTK00001",
                    name: "绿叶仙踪快乐男孩",
                    association: "查看物资",
                    zt: "",
                    ps: "未拍摄",
                    cp: "否",
                    zt2: "儿童照",
                    age: "百天/满月/1周岁..."

                }
            ],
        }
    },
    mounted() { },
    methods: {
        //关闭弹窗
        handleClose() {
            let obj = {
                flag: false
            }
            // console.log(obj);
            this.$emit('substancesCell', obj)
        },
    }
}
</script>

<style lang="less" scoped>
@import url("@/assets/css/maskCap.less");
</style>