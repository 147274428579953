<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 批量导入 -->
        <div class="mask-main">
            <div class="mask-nav ">
                <div></div>
                <div class="mask-title">
                    {{ data.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="mask-nav mask-nav1">
                <div class="mask-title">
                    <div></div>
                    {{ data.title1 }}
                </div>
            </div>
            <div class="table table1">
                <el-input type="textarea" :rows="2" placeholder="请输入审核不通过" v-model="users.reason"
                    :maxlength="data.maxlength" show-word-limit>
                </el-input>
            </div>
            <div class="mask-nav mask-nav2">
                <div class="mask-title">
                    <div></div>
                    {{ data.title2 }}
                </div>
            </div>
            <div class="table">
                <div class="download">请先<span class="downloadspan" @click="downloadExcel()">下载批量导入模板</span></div>
                <el-upload class="upload-demo" :show-file-list="true" :before-upload="beforeAvatarUpload" drag
                    :file-list="fileLists" :headers="headers" :on-success="handleSuccess" :on-remove="handleRemove"
                    :on-change="hanlechange" :action="imgurl + '/api/platform/index/uploadfile'" multiple>
                    <div class="el-upload__text">
                        <img src="@/assets/images/icon/import.png" alt="">
                        将文件拖到此处或点击上传
                    </div>
                    <div class="el-upload__tip" slot="tip"><span class="file">{{ result }}</span></div>
                </el-upload>
                <div class="save" @click="handleSubmit">{{ data.btn }}</div>
            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "UnApproved",
    props: ["data"],
    data() {
        return {
            users: {
                reason: "",
                examineattach: ''
            },
            result: "",//上传结果
            headers: {
                token: localStorage.getItem('token')
            },
            fileLists: [],
        };
    },
    mounted() { },
    methods: {
        // 下载批量导入模板
        downloadExcel() {
            let a = document.createElement("a");
            if (this.data.title == "批量导入预约单") {
                // a.href = this.imgurl + "/uploads/excel/1691222618993847.xlsx"
            } else {
                this.$message({
                    type: "error",
                    message: "暂无导入模块"
                })
            }
            document.body.appendChild(a);
            a.click();
            a.remove();
        },
        hanlechange(file, fileList) {
            console.log(URL.createObjectURL(file.raw))
        },
        handleSuccess(response, file, fileList) {
            if (response.code == 1) {
                this.fileLists.push(response.data.file)
            } else {
                this.result = response.msg
            }
        },
        handleRemove(file, fileList) {
            console.log(file);
            this.fileLists = this.fileLists.filter((item => {
                return item != file.response.data.file
            }))
        },
        beforeAvatarUpload(file) {
            const isJPG = file.name.slice(-5) === '.xlsx'
            if (!isJPG) {
                this.$message({
                    message: '上传文件只能是 Excel文件',
                    type: 'error'
                })
            }
            return isJPG
        },
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                submitFlag: false,
            }
            this.$emit("unapprovedCall", obj);
        },
        handleSubmit() {
            let obj = {
                flag: false,
                submitFlag: true,
                users: this.users
            }
            console.log(this.users);
            this.$emit("unapprovedCall", obj);
        },
    },
    watch: {
        fileLists: {
            handler(newValue, oldValue) {
                this.users.examineattach = JSON.parse(JSON.stringify(newValue)) + ''
            }
        }
    }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/.table {
    padding-top: 1.852vh;

    textarea {
        height: 33.333vh;
        resize: none;
    }
}

.download {
    font-size: 0.833vw;
    margin-bottom: 2.222vh;

    span {
        color: #FF0000;
    }
}

/deep/.upload-demo {
    .el-upload {
        width: 100%;

        .el-upload-dragger {
            width: 100%;
            height: 6.481vh;
            font-size: 0.833vw;
            display: flex;
            align-items: center;
            justify-content: center;

            .el-upload__text {
                font-size: 0.833vw;
                color: #666666;

                img {
                    width: 0.938vw;
                    height: 1.481vh;
                    margin-right: 0.573vw;
                }
            }

        }


    }

    .el-upload__tip {
        color: #FF0000;
    }
}

.order-mask {
    .mask-main {
        width: 45.365vw;

        .mask-nav1 {
            margin-top: 0.885vw;
            border-bottom: 0;
        }

        .table1 {
            padding-top: 0;
        }
    }
}

/deep/.table {
    textarea {
        height: 27.87vh;
    }
}



.mask-nav2 {
    margin-top: 1.563vw;
}
</style>