<template>
    <div class="order-mask" v-if="dataVal.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    {{ dataVal.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <table>
                    <tr>
                        <td class="table-td xin2">道具名称</td>
                        <td colspan="3">
                            <input type="text" v-model="users.name" :placeholder="'请输入道具名称'">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">道具链接</td>
                        <td colspan="3">
                            <input type="text" v-model="users.link" :placeholder="'请输入道具链接或粘贴'">
                        </td>
                    </tr>
                </table>
                <div v-if="dataVal.btn != ''" class="save" @click="handleSubmit">{{ dataVal.btn }}</div>
            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "AddMaterialLink",
    props: ["dataVal"],
    data() {
        return {
            radio: 3,
            users: {
                name: "",
                link: "",
            }
        };
    },
    mounted() {
        if (this.data) {
            if (this.data.type == 'edit') {
                this.users.id = this.data.id;
            }
        }

    },
    methods: {
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                submitFlag: false
            }
            this.$emit("addLinkCell", obj);
        },
        handleSubmit() {
            let obj = {
                flag: false,
                users: this.users,
                submitFlag: true
            }
            this.$emit("addLinkCell", obj);
        },
    },
    watch: {
        dataVal: {
            deep: true,
            handler(newvalue, oldvalue) {
                if (newvalue.type == 'edit') {
                    this.users = newvalue.users
                } else if (newvalue.type == 'add') {
                    this.users = {
                        name: "",
                        link: "",
                    }
                }
            }
        }
    }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/.el-textarea__inner {
    height: 14.259vh;
    border: none;
    resize: none;
}

.order-mask .mask-main {
    width: 32.969vw;
}

.table table {
    width: 32.969vw;
    margin-top: 1.852vh;
}

.table table input {
    padding-left: 1.25vw;
    font-size: 0.833vw;
}
</style>