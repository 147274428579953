<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {}
  }
}
</script>

<style lang="less">
body {
  margin: 0;
}

.over-auto {
  height: 85.37vh;
  overflow-y: hidden;
  overflow-x: hidden;
}

.el-table .cell {
  padding-left: 0.156vw !important;
  padding-right: 0.156vw !important;
}

::-webkit-scrollbar {
  width: 0.313vw;
  height: 0.556vh;
}

.conent {
  height: 90.9259vh;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.26vw;
  -webkit-box-shadow: inset 0 0 0.463vh rgba(0, 0, 0, 0.2);
  background-color: #99a9bf;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.463vh rgba(0, 0, 0, 0.2);
  border-radius: 0.26vw;
  background-color: #d3dce6;
}

div {
  font-size: 0.729vw;
  color: #333;
}

p {
  font-size: 0.729vw;
  color: #333;
}

span {
  font-size: 0.729vw;
}

td {
  box-sizing: border-box;
}

input,
button,
select,
textarea {
  outline: none;
}

textarea {
  font-family: auto !important;
  font-size: 0.729vw !important;
  color: #333 !important;
}

input::input-placeholder {
  font-size: 0.729vw;
  color: #999 !important;
}

input::-webkit-input-placeholder {
  font-size: 0.729vw;
  color: #999 !important;
}

input::-moz-placeholder {
  font-size: 0.729vw;
  color: #999 !important;
}

input::-moz-placeholder {
  font-size: 0.729vw;
  color: #999 !important;
}

input::-ms-input-placeholder {
  font-size: 0.729vw;
  color: #999 !important;
}

textarea::input-placeholder {
  font-size: 0.729vw;
  color: #999 !important;
  font-family: none;
}

textarea::-webkit-input-placeholder {
  font-size: 0.729vw;
  color: #999 !important;
  font-family: none;
}

textarea::-moz-placeholder {
  font-size: 0.729vw;
  color: #999 !important;
  font-family: none;
}

textarea::-moz-placeholder {
  font-size: 0.729vw;
  color: #999 !important;
  font-family: none;
}

textarea::-ms-input-placeholder {
  font-size: 0.729vw;
  color: #999 !important;
  font-family: none;
}

.conent {
  padding-bottom: 2.188vw;
}

.conent {
  overflow-y: hidden
}

/* input 类型为 number 时去掉数字加减上下箭头 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* 此处写不写都可以 */
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input[type="number"] {
  -moz-appearance: textfield;
  /* 此处写不写都可以 */
}

/deep/.el-table .el-table__cell {
  padding: 0.741vh 0;
}

.el-table .el-table__cell {
  padding: 0.741vh 0;
}

.el-date-editor .el-range-input {
  font-size: 0.729vw;
}

.el-date-editor .el-range-separator {
  font-size: 0.729vw;
  padding: 0 0.26vw;
  line-height: 2.963vh;
}

.el-pagination button,
.el-pagination span:not([class*=suffix]) {
  display: inline-block;
  font-size: 0.729vw;
  min-width: 1.849vw;
  height: 3.333vh;
  line-height: 3.241vh;
  vertical-align: top;
  box-sizing: border-box;
}

.el-pagination__editor.el-input .el-input__inner {
  height: 3.241vh;
  font-size: 0.729vw;
}

.table table {
  width: 100% !important;
}

.el-pager li {
  height: 3.333vh;
  line-height: 3.333vh;
}




.el-select .el-input .el-select__caret {
  font-size: 0.73vw;
}

.el-cascader .el-input .el-icon-arrow-down {
  font-size: 0.73vw;
}

.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  font-size: 0.73vw;
}

// 级联选择器下拉框样式 -- 开始
.el-cascader-menu {
  min-width: 10vw !important;
}

.el-cascader-menu__wrap {
  height: 18.889vh;
}

.el-cascader-menu__list>.el-cascader-node {
  padding: 0 1.562vw 0 1.044vw;
  height: 3.148vh !important;
  line-height: 3.148vh !important;
}

.el-cascader-node__label {
  padding: 0 0.521vw;
}

.el-icon-circle-close:before {
  font-size: 0.73vw;
}

// 级联选择器下拉框样式 -- 结束

// 时间选择器 -- 开始
.el-date-range-picker {
  // width: 33.65vw;
  width: auto;
}

.el-date-range-picker__header {
  height: 2.593vh;
  margin-top: 0.741vh !important;
  padding: 0.052vw 0.556vh !important;
}

.el-date-range-picker__time-header {
  line-height: 2.13vh;
  font-size: 0.63vw;
  padding: 0.741vh 0.26vw 0.463vh;
}

.el-date-range-picker__time-header>.el-icon-arrow-right {
  font-size: 1.042vw;
}

.el-input--small {
  font-size: 0.68vw;

  .el-input__inner {
    height: 2.963vh;
    line-height: 2.963vh;
    padding: 0 0.781vw;
    font-size: 0.63vw;
  }
}

.el-date-range-picker .el-picker-panel__body {
  // width: 33.65vw;
  // min-width: 33.65vw;
  width: auto;
  min-width: 0;
}

.el-date-range-picker__content {
  padding: 0.833vw;
}

.el-picker-panel__icon-btn {
  font-size: 0.63vw;
  margin-top: 0.741vh;
}

.el-date-range-picker__content .el-date-range-picker__header div {
  margin: 0 2.604vw;
  font-size: 0.833vw;
  line-height: 2.778vh;
}

.el-date-table th {
  padding: 0.26vw;
  font-size: 0.63vw;
  line-height: 2.778vh;
}

.el-date-table td {
  width: 1.67vw;
  height: 2.778vh;
  padding: 0.37vh 0;
}

.el-date-table td div {
  height: 2.778vh;
  padding: 0.278vh 0;
}

.el-date-table td span {
  width: 1.25vw;
  height: 1.25vw;
  line-height: 1.25vw;
}

.el-picker-panel__footer {
  padding: 0.21vw;
  line-height: 2.778vh;
}

.el-button--mini {
  padding: 0.648vh 0.781vw;
  font-size: 0.63vw;

  span {
    font-size: 0.63vw;
  }
}

.el-time-panel__content::after,
.el-time-panel__content::before {
  height: 2.963vh;
  margin-top: -1.389vh;
  padding-top: 0.556vh;
  border-top: 0.052vw solid #E4E7ED;
  border-bottom: 0.093vh solid #E4E7ED;
}

.el-time-spinner__wrapper {
  max-height: 17.593vh;
}

.el-time-spinner__list::after,
.el-time-spinner__list::before {
  height: 7.407vh;
}

//。。。。。。
.el-time-spinner__item {
  height: 2.963vh;
  line-height: 2.963vh;
  font-size: 0.63vw;
}

.el-time-panel__footer {
  padding: 0.21vw;
  height: 3.333vh;
  line-height: 2.315vh;

  .el-time-panel__btn {
    line-height: 2.593vh;
    padding: 0 0.26vw;
    margin: 0 0.26vw;
    font-size: 0.63vw;
  }
}

.el-date-editor .el-range__icon {
  font-size: 0.73vw;
}

.el-range-editor.el-input__inner {
  padding: 0.278vh 0.521vw;
  border: 0.052vw solid #DCDFE6;
}

.el-date-editor--daterange.el-input__inner {
  width: 18.229vw;
}

// 时间（单）选择器
.el-date-picker {
  width: 16.771vw;
  margin-top: 1.111vh !important;
  // top: 14.167vh !important;
  // left: 16.615vw !important;
  // border: 0.052vw solid #E4E7ED;
}

.el-date-picker__header {
  margin: 0.625vw;
  line-height: 0;

  .el-date-picker__header-label {
    font-size: 0.833vw;
    padding: 0 0.26vw;
    line-height: 2.037vh;
  }
}

.el-date-picker__time-header {
  font-size: 0.625vw;
  padding: 0.741vh 0.26vw 0.463vh;

  .el-date-picker__editor-wrap {
    padding: 0 0.26vw;

    .el-input {
      display: flex;
    }

    .el-input__inner {
      border-radius: 0.208vw !important;
      border: 0.052vw solid #DCDFE6 !important;
    }

    .el-time-panel {
      width: 9.375vw;
      margin: 0.463vh 0;
      border: 0.052vw solid #E4E7ED;
    }
  }
}

.el-picker-panel__content {
  width: 15.208vw !important;
  margin: 0.781vw;
}

// 时间日期选择器
.el-time-spinner__wrapper .el-scrollbar__wrap:not(.el-scrollbar__wrap--hidden-default) {
  padding-bottom: 1.3889vh;
}

.el-scrollbar__wrap {
  margin-bottom: -0.27778vh !important;
  margin-right: -0.156vw !important;
}

.el-scrollbar__bar.is-horizontal {
  height: 0.556vh;
  left: 0.104vw;
}

.el-scrollbar__bar {
  right: 0.104vw;
  bottom: 0.185vh;
  border-radius: 0.208vw;
}

.el-scrollbar__bar.is-vertical {
  width: 0.313vw;
  top: 0.185vh;
}

// 时间选择器 -- 结束

// 消息提示 -- 开始
.el-message {
  top: 1.852vh !important;
  min-width: 19.792vw;
  border-radius: 0.21vw;
  border-width: 0.052vw;
  padding: 1.389vh 0.781vw 1.389vh 1.042vw;

  .el-message__content {
    font-size: 0.73vw;
  }
}

// 消息提示 -- 结束

// 开关 -- 开始
.el-switch__core {
  width: 2.083vw !important;
  height: 1.042vw !important;
  border: 0.052vw !important;
  line-height: 1.852vh !important;
}

.el-switch__core:after {
  width: 0.833vw !important;
  height: 0.833vw !important;
  margin-left: -0.885vw !important;
  top: 0.13vh !important;
  left: 0.885vw;
  line-height: 1.852vh;
}

// 开关 -- 结束

// 分页器下拉框
.paging {
  min-width: 2.383vw !important;
  // top: 74vh !important;
  // left: 46.5vw !important;
  margin: 0.463vh 0 !important;
  margin-bottom: 1.111vh !important;

  .el-scrollbar {
    .el-select-dropdown__wrap {
      max-height: 25.37vh !important;
    }
  }
}

.el-select-dropdown__item {
  font-size: 0.73vw !important;
  padding: 0 1.042vw !important;
  height: 3.148vh !important;
  line-height: 3.148vh !important;

  span {
    font-size: 0.73vw !important;
  }
}

// 分页器 -- 结束

/deep/.el-input {
  display: flex;
  align-items: center;
}

/deep/.el-upload--picture-card {
  display: flex;
  justify-content: center;
}

// 单选框
.el-radio {
  margin-right: 1.563vw;

  .el-radio__inner {
    width: 0.729vw;
    height: 0.729vw;
  }

  .el-radio__inner::after {
    width: 0.208vw;
    height: 0.208vw;
  }

  .el-radio__label {
    padding-left: 0.521vw;
    font-size: 0.729vw;
  }
}

// 单选框
// 多选框
.el-checkbox {
  font-size: 0.729vw;
  margin-right: 1.563vw;

  .el-checkbox__input {
    .el-checkbox__inner {
      width: 0.729vw;
      height: 0.729vw;
      border-radius: 0.104vw;
    }

    .el-checkbox__inner::after {
      width: 0.208vw;
      height: 0.208vw;
      // border: 0.052vw solid #FFF;
      left: 0.208vw;
      top: 0.093vh;
    }
  }

  .el-checkbox__label {
    font-size: 0.729vw;
    padding-left: 0.521vw;
    line-height: 1.759vh;
  }
}

//多选框

.el-avatar,
.el-cascader-panel,
.el-radio,
.el-radio--medium.is-bordered .el-radio__label,
.el-radio__label {
  font-size: 0.729vw;
}

// 文本域长度限制
.el-textarea .el-input__count {
  font-size: 0.625vw;
  bottom: 0.463vh;
  right: 0.521vw;
}

button {
  padding: 0.093vh 0.313vw;
  font-size: 0.729vw;
}

.el-input {
  font-size: 0.68vw;

  .el-input__inner {
    height: 2.963vh;
    line-height: 2.963vh;
    padding: 0 0.781vw;
    font-size: 0.63vw;
  }
}

.el-textarea__inner {
  padding: 0.463vh 0.781vw;
  border: 0.052vw solid #DCDFE6;
  border-radius: 0.208vw;
}

// 下拉菜单
.el-dropdown-menu {
  padding: 0.926vh 0;
  margin: 0.463vh 0;
  border: 0.052vw solid #EBEEF5;
  border-radius: 0.208vw;
  box-shadow: 0 0.104vw 1.111vh 0 rgba(0, 0, 0, .1);

  .el-dropdown-menu__item {
    line-height: 3.333333vh;
    font-size: 0.729vw;
    padding: 0 1.042vw;
  }
}

//MessageBox 弹框 退出登录
.el-message-box {
  width: 21.875vw;
  padding-bottom: 0.926vh;
  border-radius: 0.208vw;
  border: 0.052vw solid #EBEEF5;
  font-size: 0.938vw;
  box-shadow: 0 0.104vw 1.111vh 0 rgba(0, 0, 0, .1);

  .el-message-box__header {
    padding: 1.3889vh 0.781vw 0.926vh;

    .el-message-box__title {
      font-size: 0.938vw;
    }

    .el-message-box__headerbtn {
      font-size: 0.729vw;
      top: 1.3889vh;
      right: 0.781vw;
    }
  }

  .el-message-box__btns {
    .el-button {
      font-size: 0.729vw;
    }
  }

  .el-message-box__content {
    padding: 0.926vh 0.781vw;
    font-size: 0.729vw;

    .el-message-box__container {
      .el-message-box__status {
        font-size: 1.25vw !important;
      }

      .el-message-box__status+.el-message-box__message {
        padding-left: 1.875vw;
        padding-right: 0.625vw;

        p {
          line-height: 2.315vh;
        }
      }
    }
  }

  .el-message-box__btns {
    padding: 0.463vh 0.781vw 0;

    .el-button--small {
      padding: 0.8333vh 0.781vw;
      font-size: 0.625vw;
      border-radius: 0.156vw;
    }

    .el-button {
      border: 0.052vw solid #DCDFE6;
    }
  }
}
</style>
