<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="nav">
                <div class="navfont1">×</div>
                <div class="navfont2">{{ data.title }}</div>
                <div class="navfont1 navfont3" @click="handleRefund">×</div>
            </div>
            <div class="mask_main_cen">
                <div class="mask_main_l">
                    <div class="main_cen_h">
                        <div class="main_l">
                            <div class="xian"></div>
                            <div class="title">充值金额设定</div>
                        </div>
                        <div class="main_r" @click="handleAdd">新增</div>
                    </div>
                    <div class="mask_main_table" style="">
                        <el-table id="filei" class="center_table" ref="multipleTable" :data="tableDatalist"
                            tooltip-effect="dark" style="width: 100%;" border :header-cell-style="{
                                width: 40.323 + 'vw',
                                height: 5.185 + 'vh',
                                background: '#F9F9F9',
                                fontSize: 0.833 + 'vw',
                                textAlign: 'center',
                            }" @selection-change="handleSelectionChange">
                            <el-table-column type="index" min-width="7.3px" align="center"></el-table-column>
                            <el-table-column prop="setmoney" label="充值金额" align="center" min-width="14.2px">
                                <template slot-scope="scope">
                                    <input type="number" class="inp_one" v-if="money.id == scope.row.id"
                                        v-model="money.setmoney">
                                    <div v-if="money.id != scope.row.id">{{ scope.row.setmoney }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="givemoney" label="赠送金额" align="center" min-width="14.2px">
                                <template slot-scope="scope">
                                    <input type="number" class="inp_one" v-if="money.id == scope.row.id"
                                        v-model="money.givemoney">
                                    <div v-if="money.id != scope.row.id">{{ scope.row.givemoney }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center" min-width="14.2px">
                                <template slot-scope="scope">
                                    <span v-if="money.id != scope.row.id" class="edit"
                                        @click="money.id = scope.row.id; money.setmoney = scope.row.setmoney; money.givemoney = scope.row.givemoney">编辑</span>
                                    <span v-if="money.id == scope.row.id" class="edit" @click="rechargeMoneyedit">保存</span>
                                    <span v-if="scope.row.id != null && scope.row.id != ''" class="clear"
                                        @click="handlePushs(scope.row.id)">清空</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <!-- <div class="mask_main_r">
                    <div class="main_cen_h">
                        <div class="xian"></div>
                        <div class="title">支付方式</div>
                    </div>
                    <div class="payment">
                        <div class="zfb">
                            <el-upload class="avatar-uploader" :action="uploadimg" :on-success="handleAvatarSuccess1"
                                :headers="headers" :before-upload="beforeAvatarUpload" :on-remove="handleRemove1"
                                :show-file-list="false">
                                <img v-if="image1" :src="imgurl + image1" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <div>支付宝收款二维码</div>
                        </div>
                        <div class="zfb">
                            <el-upload class="avatar-uploader" :action="uploadimg" :on-success="handleAvatarSuccess2"
                                :headers="headers" :before-upload="beforeAvatarUpload" :on-remove="handleRemove2"
                                :show-file-list="false">
                                <img v-if="image2" :src="imgurl + image2" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <div>支付宝收款二维码</div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="footer">
                <div class="btn" @click="handleSubmit">保存</div>
            </div>
        </div>
        <!-- 确定弹窗 批量删除/批量启用 -->
        <MaskPack :data="pushObj" @childRef="handlePushsCall"></MaskPack>
    </div>
</template>
        
<script>
export default {
    name: "MoneyList",
    props: ["data"],
    data() {
        return {
            tableDatalist: [],
            headers: {
                token: localStorage.token
            },
            image1: '',
            image2: '',

            money: {
                id: '',//编辑充值金额id
                setmoney: '',//充值的金额
                givemoney: '',//赠送的金额
            },


            pushObj: {
                flag: false,//是否显示
                title: "删除充值金额",//标题
                text: "确认要删除该充值金额吗？",//内容
                btns: "确定",//确定按钮
                btnq: "关闭",//取消按钮
            },
        };
    },
    mounted() {
        this.rechargeMoneylist()
    },
    methods: {
        // 新增
        handleAdd() {
            this.money = {
                id: '',
                setmoney: '',
                givemoney: '',
            }
            if (this.tableDatalist.length >= 12) {
                this.$message.warning('最多只能添加12条充值金额')
            } else {
                this.tableDatalist.push({
                    id: '',
                    setmoney: '',
                    givemoney: '',
                })
            }
        },
        // 编辑
        async rechargeMoneyedit() {
            let res = await this.$api.rechargeMoneyedit(this.money)
            if (res.code == 1) {
                this.money = {
                    id: '',
                    setmoney: '',
                    givemoney: '',
                }
                this.rechargeMoneylist()
                this.$message.success('编辑成功')
            }
        },
        handlePushs(id) {
            this.pushObj = {
                flag: true,//是否显示
                title: "删除充值金额",//标题
                text: "确认要删除该充值金额吗？",//内容
                btns: "确定",//确定按钮
                btnq: "关闭",//取消按钮
                id: id
            }
        },
        async handlePushsCall(obj) {
            if (obj.submitFlag) {
                let res = await this.$api.rechargeMoneydel({
                    id: this.pushObj.id
                })
                if (res.code == 1) {
                    this.rechargeMoneylist()
                    this.pushObj.flag = false
                }
            } else {
                this.pushObj.flag = false
            }
        },
        //取消弹窗
        handleRefund() {

            this.tableDatalist = this.tableDatalist.filter(item => {
                return item.id != null && item.id != ''
            })

            let obj = {
                flag: false,
                submitFlag: false,
            }
            this.$emit("moneylistCall", obj);
        },
        handleSubmit() {

            this.tableDatalist = this.tableDatalist.filter(item => {
                return item.id != null && item.id != ''
            })

            let obj = {
                flag: false,
                submitFlag: true,
            }
            this.$emit("moneylistCall", obj);
        },
        //图片上传成功
        handleAvatarSuccess1(response, res, file) {
            if (response.code == 1) {
                this.image1 = response.data.imgsrc
            }
        },
        handleRemove1(file, fileList) {
            this.image1 = ""
        },
        handleAvatarSuccess2(response, res, file) {
            if (response.code == 1) {
                this.image2 = response.data.imgsrc
            }
        },
        handleRemove2(file, fileList) {
            this.image2 = ""
        },
        //图片上传前
        beforeAvatarUpload(file) {
            console.log(file);
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            return isJPG;
        },
        async rechargeMoneylist() {
            let res = await this.$api.rechargeMoneylist()
            if (res.code == 1) {
                this.tableDatalist = res.data
            }
        }
    },
    watch: {
        data: {
            handler(newVal, oldVal) {
                this.money = {
                    id: '',
                    setmoney: '',
                    givemoney: '',
                }
            },
            deep: true,
        }
    }
};
</script>
        
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.inp_one {
    width: 6.615vw;
    height: 2.396vw;
    background-color: #ffffff;
    border-radius: 0.521vw;
    border: solid 0.052vw #e6e6e6;
    text-align: center;
}

/deep/.el-table--border {
    height: 59.677vh !important;
}

/deep/.el-table__body-wrapper {
    height: 54.492vh;
    overflow-x: scroll;
    overflow-y: scroll;
}

/deep/.avatar-uploader-icon {
    width: 24.194vh;
    height: 24.194vh;
    line-height: 24.194vh;
    background-color: #ffffff;
    border-radius: 0.806vh;
    border: solid 0.121vh #0f5df9;
}

/deep/.avatar-uploader .el-upload {
    // width: 100%;
    // height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;

    .avatar {
        width: 24.194vh;
        max-height: 24.194vh !important;
        // width: auto;
        height: auto;
    }
}



.order-mask {

    .mask-main {
        //width: 44.272vw;
        width: auto;
        padding: 1.51vw 2.083vw;

        .nav {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 1.198vw;
            border-bottom: 0.052vw solid #e1e1e1;

            .navfont1 {
                font-family: MicrosoftYaHeiLight;
                font-size: 1.875vw;
                color: #ffffff;
            }

            .navfont2 {
                font-family: MicrosoftYaHei-Bold;
                font-size: 0.885vw;
                font-weight: bold;
                color: #333333;
            }

            .navfont3 {
                color: #333333;
                cursor: pointer;
            }
        }

        .mask_main_cen {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 1.094vw;

            .mask_main_l {
                width: 26.094vw;
                display: flex;
                flex-wrap: wrap;

                .main_cen_h {
                    width: 100%;
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-between;

                    .main_l {
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;

                        .xian {
                            width: 0.208vw;
                            height: 0.885vw;
                            background-color: #2c72ff;
                        }

                        .title {
                            font-weight: bold;
                            font-family: MicrosoftYaHei-Bold;
                            font-size: 0.833vw;
                            color: #333333;
                            margin-left: 0.521vw;
                        }
                    }

                    .main_r {
                        padding: 0.347vw 1.042vw;
                        background-color: #2c72ff;
                        font-family: MicrosoftYaHei-Bold;
                        font-size: 0.833vw;
                        color: #fff;
                        border-radius: .2083vw;
                        cursor: pointer;
                    }

                }

                .mask_main_table {
                    width: 100%;
                    margin-top: 1.25vw;

                    /deep/.el-table__body-wrapper is-scrolling-none {
                        overflow: scroll;
                    }

                    .edit {
                        font-family: MicrosoftYaHei;
                        font-size: 1.29vh;
                        color: #2c72ff;
                        cursor: pointer;
                    }

                    .clear {
                        font-family: MicrosoftYaHei;
                        font-size: 1.29vh;
                        color: #2c72ff;
                        cursor: pointer;
                        margin-left: 1.042vw;
                    }
                }
            }

            .mask_main_r {
                width: 15.625vw;
                margin-left: 2.552vw;

                .main_cen_h {
                    width: 100%;
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: flex-start;

                    .xian {
                        width: 0.208vw;
                        height: 0.885vw;
                        background-color: #2c72ff;
                    }

                    .title {
                        font-weight: bold;
                        font-family: MicrosoftYaHei-Bold;
                        font-size: 0.833vw;
                        color: #333333;
                        margin-left: 0.521vw;
                    }
                }

                .payment {
                    width: 100%;
                    margin-bottom: 2.552;

                    .zfb {
                        width: 100%;
                        margin: 1.25vw 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }
        }

        .footer {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 0.157vw;
            margin-top: 1.042vw;

            .btn {
                width: 6.25vw;
                height: 2.448vw;
                line-height: 2.448vw;
                text-align: center;
                font-family: MicrosoftYaHei;
                font-size: 0.885vw;
                color: #ffffff;
                background-color: #0f5df9;
                border-radius: 0.521vw;
                cursor: pointer;
            }
        }

    }
}
</style>