<template>
    <div class="order-mask" v-if="dataval.flag">

        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    {{ dataval.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <table>
                    <tr>
                        <td class="table-td xin2">广告标题</td>
                        <td colspan="3">
                            <input type="text" v-model="users.title" placeholder="请输入广告标题">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">广告展示位</td>
                        <td colspan="3">
                            <el-radio-group v-model="radio">
                                <el-radio v-for="item in location_type" :label="item.value" :key="item.value"
                                    :value="item.value">
                                    {{ item.label }}
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">轮播顺序</td>
                        <td colspan="3">
                            <el-select v-model="users.orderlist" placeholder="请选择" class="selec">
                                <el-option v-for="item in rotation_order" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">是否启用</td>
                        <td colspan="3">
                            <div ock style="padding-left: 1.25vw;">
                                <el-switch v-model="users.status" active-color="#13ce66" inactive-color="#D7D6D6">
                                </el-switch>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">广告类型</td>
                        <td colspan="3">
                            <el-select :popper-append-to-body="false" v-model="users.type" placeholder="请选择广告类型"
                                class="selec">
                                <el-option v-for="item in ad_type" :key="item.values" :label="item.values"
                                    :value="item.values">
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                </table>
                <div class="cen-c">
                    <div class="cen-c1"></div>
                    <div class="cen-c2">广告展示图</div>
                </div>
                <div class="upload">
                    <el-upload class="avatar-uploader" :action="uploadimg" :show-file-list="true"
                        :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :headers="headers"
                        accept="image/jpg,image/jpeg,image/png">
                        <img v-if="users.image" :src="imgurl + users.image" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <div class="upload-tip">（图片尺寸为*******，单位像素，不超过2M，支持PNG、JPEG、JPG）</div>
                </div>
                <div v-if="dataval.btn != ''" class="save" @click="handleSubmit">{{ dataval.btn }}</div>
            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "AddAuth",
    props: ["dataval"],
    data() {
        return {
            radio: "",
            headers: {
                token: localStorage.token
            },
            users: {
                title: "",
                orderlist: "",
                type: "",
                status: true,
                image: '',
                location: 1,
            },
            location_type: [
                {
                    "value": '1',
                    "label": "商家小程序"
                },
                {
                    "value": '2',
                    "label": "商家PC端"
                }
            ],
            rotation_order: [
                {
                    "value": 1,
                    "label": 1
                },
                {
                    "value": 2,
                    "label": 2
                },
                {
                    "value": 3,
                    "label": 3
                },
                {
                    "value": 4,
                    "label": 4
                },
                {
                    "value": 5,
                    "label": 5
                }
            ],
            ad_type: [
            ],
            headers: {
                token: localStorage.getItem('token')
            }
        };
    },
    mounted() {
    },
    methods: {
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                typeText: "",
            }
            this.$emit("addAccountCell", obj);
            this.users = {
                title: "",
                orderlist: "",
                type: "",
                status: true,
                image: '',
                location: '',
            }
        },
        handleSubmit() {
            let obj = {
                flag: false,
                typeText: "",
                users: {
                    title: this.users.title,
                    orderlist: this.users.orderlist,
                    type: this.users.type,
                    status: this.users.status ? 1 : 0,
                    image: this.users.image,
                    location: this.radio,
                }
            }
            this.$emit("addAccountCell", obj);
        },
        //图片
        handleAvatarSuccess(response, res, file) {
            if (response.code == 1) {
                this.users.image = JSON.parse(JSON.stringify(response.data.imgsrc.replace(/,/g, ""))) + ""
            }
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            const isLt2M = file.size / 1024 / 1024 < 20;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 20MB!');
            }
            return isJPG && isLt2M;
        },
        async adGettype() {
            let res = await this.$api.adGettype()
            if (res.code == 1) {
                this.ad_type = res.data
            }
        }
    },
    created() {
        this.adGettype()
    },
    watch: {
        dataval: {
            deep: true,
            handler(newvalue, oldvalue) {
                if (newvalue.type == "edit") {
                    this.users = {
                        title: newvalue.obj.title,
                        orderlist: newvalue.obj.orderlist,
                        type: newvalue.obj.type,
                        status: newvalue.obj.status == "1" ? true : false,
                        image: newvalue.obj.image,
                    }
                    this.location_type.forEach(element => {
                        if (element.label == newvalue.obj.location) {
                            this.radio = element.value
                        }
                    })
                } else {
                    this.users = {
                        title: "",
                        orderlist: "",
                        type: "",
                        status: true,
                        image: "",
                        location: 1,
                    }
                }
            }
        }
    }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask .mask-main {
    width: 32.969vw;
}

.table table {
    width: 32.969vw;
    margin-top: 1.852vh;
}

.table table input {
    padding-left: 1.25vw;
    font-size: 0.833vw;
}

.selec {
    position: relative;

    /deep/.el-select-dropdown {
        position: absolute !important;
        top: 3.628vh !important;
        left: 0;

        .el-scrollbar {
            height: 24.167vh;
        }
    }
}

/deep/.el-scrollbar__bar.is-vertical {
    width: 0.313vw;
    top: 0.185vh;
}

/deep/ .el-input--suffix .el-input__inner {
    padding-left: 1.25vw;
}

.cen-c {
    display: flex;
    align-items: center;
    margin-top: 3.796vh;
    font-size: 0.885vw;
    font-weight: bold;
}

.cen-c1 {
    width: 0.208vw;
    height: 1.481vh;
    background: #066FF8;
    margin-right: 0.521vw;
}

.upload {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .upload-tip {
        color: #B2AFAF;
        margin-top: 2.222vh;
    }
}
</style>
<style lang="less">
.avatar-uploader .el-upload {
    border: 0.052vw dashed #d9d9d9;
    border-radius: 0.313vw;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 1.458vw;
    color: #8c939d;
    width: 13.281vw;
    height: 11.111vh;
    line-height: 11.111vh;
    text-align: center;
}

.avatar {
    max-width: 13.281vw !important;
    max-height: 11.111vh !important;
    display: block;
}

.radiotyle {
    display: flex;
    padding-left: 1.198vw;
}
</style>