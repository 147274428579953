//全局注册组件
//拍摄完成/拍摄未完成弹窗
import Tag from '@/components/Tag.vue';
//拍摄完成/拍摄未完成弹窗
import Capture from '@/components/management/Capture.vue';
//批量推送预约单
import MaskPack from '@/components/management/MaskPack.vue';
//上传底片
import FailureReason from '@/components/management/FailureReason.vue';
//批量导入
import BatchImport from '@/components/management/BatchImport.vue';
//预约信息查看
import Meservation from '@/components/management/Meservation.vue';
//添加角色
import AddAuth from '@/components/system/AddAuth.vue';
//添加子账号
import AddAccount from '@/components/system/AddAccount.vue';
//新增请求参数
import AddParameters from '@/components/system/AddParameters.vue';
//关联物资
import ViewSupplies from '@/components/management/calmetrics/ViewSupplies.vue';
//添加通知消息
import AddNotice from '@/components/management/message/AddNotice.vue';
//添加通知消息
import AddTheme from '@/components/management/shootingTheme/AddTheme.vue';
//添加广告
import AddAdvertisement from '@/components/management/advertisement/AddAdvertisement.vue';
//查看附件
import ViewAttachments from '@/components/management/evaluation/ViewAttachments.vue';
//查看处理结果
import ProcessingProgress from '@/components/management/evaluation/ProcessingProgress.vue';
//新增模特
import AddModelManagment from '@/components/management/model/AddModelManagment.vue';
//服装品控用户协议
import TipModel from '@/components/management/model/TipModel.vue';
//签订协议，失败原因，重新发送
import FileModel from '@/components/management/model/FileModel.vue';
//关联物资
import ViewMaterial from '@/components/management/material/ViewMaterial.vue';
//新增物资购买链接
import AddMaterialLink from '@/components/management/material/AddMaterialLink.vue';
//新增物资购买链接
import FailReason2 from '@/components/management/FailReason.vue';
//新增物资购买链接
import FailReasonDis from '@/components/management/FailReason2.vue';
//重新绑定 二维码
import BindingAccount from '@/components/system/BindingAccount.vue';
//批量导入图库
import GalleryImport from '@/components/management/GalleryImport.vue';
// 新增名师
import AddTeacher from '@/components/library/AddTeacher.vue';
// 图库审核通过
import Approved from '@/components/library/Approved.vue';
// 图库审核不通过
import UnApproved from '@/components/library/UnApproved.vue';
// 添加道具链接
import AddLink from '@/components/library/AddLink.vue';
// 设置充值金额
import MoneyList from '@/components/library/MoneyList.vue';
// 展示小程序二维码 易派德
import Resershow from '@/components/management/Resershow.vue';
// 图片预览
import ImgPreview from '@/components/library/ImgPreview.vue';
// 公众号配置
import allocation from '@/components/management/allocation.vue';
// ERP对接
import ErpIntegration from '@/components/management/ErpIntegration.vue';

//数组存放接收的组件名
const components = [
  { name: "Capture", con: Capture },
  { name: "MaskPack", con: MaskPack },
  { name: "FailureReason", con: FailureReason },
  { name: "BatchImport", con: BatchImport },
  { name: "Meservation", con: Meservation },
  { name: "AddAuth", con: AddAuth },
  { name: "AddAccount", con: AddAccount },
  { name: "AddParameters", con: AddParameters },
  { name: "ViewSupplies", con: ViewSupplies },
  { name: "AddNotice", con: AddNotice },
  { name: "AddTheme", con: AddTheme },
  { name: "AddAdvertisement", con: AddAdvertisement },
  { name: "ViewAttachments", con: ViewAttachments },
  { name: "ProcessingProgress", con: ProcessingProgress },
  { name: "AddModelManagment", con: AddModelManagment },
  { name: "TipModel", con: TipModel },
  { name: "FileModel", con: FileModel },
  { name: "ViewMaterial", con: ViewMaterial },
  { name: "AddMaterialLink", con: AddMaterialLink },
  { name: "Tag", con: Tag },
  { name: "FailReason2", con: FailReason2 },
  { name: "FailReasonDis", con: FailReasonDis },
  { name: "BindingAccount", con: BindingAccount },
  { name: "GalleryImport", con: GalleryImport },
  { name: "AddTeacher", con: AddTeacher },
  { name: "Approved", con: Approved },
  { name: "UnApproved", con: UnApproved },
  { name: "AddLink", con: AddLink },
  { name: "MoneyList", con: MoneyList },
  { name: "Resershow", con: Resershow },
  { name: "ImgPreview", con: ImgPreview },
  { name: "allocation", con: allocation },
  { name: "ErpIntegration", con: ErpIntegration },
]
//循环注册组件
const install = function (Vue, opts = {}) {
  components.forEach(component => {
    Vue.component(component.name, component.con);
  });
};

export default {
  install
};
