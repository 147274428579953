<template>
  <div class="order-mask" v-if="negative.negativeFlag">
    <!-- 上传底片 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <div></div>
          {{ negative.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="table">
        <!-- <table v-if="negative.type == 3">
          <tr>
            <td class="table-td xin2">详细地址</td>
            <td colspan="3">
              <el-radio-group v-model="radio">
                <el-radio :label="3">否</el-radio>
                <el-radio :label="6">指定服装</el-radio>
                <el-radio :label="9">客户自选</el-radio>
              </el-radio-group>
            </td>
          </tr>
        </table> -->
        <el-input type="textarea" :placeholder="negative.name" v-model="textarea" show-word-limit>
        </el-input>
        <div v-if="negative.btn != ''" class="save" @click="handleSubmit">{{ negative.btn }}</div>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "FailReason",
  props: ["negative"],
  data() {
    return {
      textarea: "",
      radio: 3
    };
  },
  mounted() { },
  methods: {
    //取消弹窗
    handleRefund() {
      let obj = {
        negativeFlag: false,
        submit: false,
      }
      this.$emit("negativeCell2", obj);
    },
    handleSubmit() {
      let obj = {
        negativeFlag: false,
        submit: true,
        typeText: this.textarea
      }
      this.$emit("negativeCell2", obj);
    },
  },
  watch: {
    negative: {
      deep: true,
      handler(newVal, oldVal) {
        // console.log(newVal);
        this.textarea = newVal.textarea
      }
    }
  }
};
</script>
    
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/.table {
  padding-top: 1.852vh;

  textarea {
    height: 33.333vh;
    resize: none;
  }

  .el-radio-group {
    text-align: left;
    padding-left: 2.76vw;

    .el-radio__label {
      font-size: 0.833vw;
    }
  }
}

.el-textarea__inner {
  font-size: 1.042vw !important;
}
</style>