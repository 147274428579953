<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    {{ data.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <table>
                    <tr>
                        <td class="table-td xin2">购买渠道</td>
                        <td colspan="3">
                            <input type="text" v-model="users.value" :placeholder="'请输入道具名称'">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">渠道链接</td>
                        <td colspan="3">
                            <input type="text" v-model="users.value" :placeholder="'请输入道具链接或粘贴'">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td">备注</td>
                        <td colspan="3">
                            <el-input type="textarea" placeholder="请输入备注" v-model="users.textarea" :maxlength="300"
                                show-word-limit>
                            </el-input>
                        </td>
                    </tr>
                </table>
                <div v-if="data.btn != ''" class="save" @click="handleSubmit">{{ data.btn }}</div>
            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "AddMaterialLink",
    props: ["data"],
    data() {
        return {
            radio: 3,
            users: {
                value: "",
                textarea: ""
            }
        };
    },
    mounted() {
        if (this.data.type == 'edit') {
            this.users.name = this.data.value;
        }
    },
    methods: {
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                typeText: "",
                users: this.users
            }
            this.$emit("addMaterialLinkCell", obj);
        },
        handleSubmit() {
            this.handleRefund();
        },
    },
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/.el-textarea__inner {
    height: 14.259vh;
    border: none;
    resize: none;
}

.order-mask .mask-main {
    width: 32.969vw;
}

.table table {
    width: 32.969vw;
    margin-top: 1.852vh;
}

.table table input {
    padding-left: 1.25vw;
    font-size: 0.833vw;
}
</style>