<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    {{ data.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <el-input type="textarea" v-model="textarea" show-word-limit>
                </el-input>
                <div v-if="data.btn != ''" class="save" @click="handleSubmit">{{ data.btn }}</div>
            </div>
        </div>
    </div>
</template>
        
<script>
export default {
    name: "AddAuth",
    props: ["data"],
    data() {
        return {
            textarea: ""
        };
    },
    created() {
        console.log(this.data)
    },
    mounted() {
    },
    methods: {
        //取消弹窗
        handleRefund() {
            this.$emit("fileModelCell", { flag: false, flags: false });
        },
        handleSubmit() {
            let obj = {
                flag: false,
                flags: true,
                textarea: this.textarea,
            }
            this.$emit("fileModelCell", obj);
        },
        async modelerGetprotocol() {
            let res = await this.$api.modelerGetprotocol()
            if (res.code == "1") {
                if (res.data) {
                    this.textarea = res.data.content
                }
            }

        }
    },
    watch: {
        data: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal.text) {
                    this.textarea = newVal.text
                } else {
                    this.modelerGetprotocol()
                }
            }
        }
    }
};
</script>
        
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask .mask-main {
    width: 37.917vw;
}

.table table {
    width: 37.917vw;
    margin-top: 1.852vh;
}

.table table input {
    padding-left: 1.25vw;
    font-size: 0.833vw;
}

/deep/.el-textarea__inner {
    height: 70vh;
    margin-top: 1.852vh;
    font-size: 0.833vw;
    resize: none;
    font-family: auto;
}
</style>