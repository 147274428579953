<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    新增消息
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <div class="message">
                    <div class="message-item">
                        <div class="message-title xin2">
                            标题
                        </div>
                        <div class="message-main">
                            <el-input v-model="input" placeholder="请输入内容"></el-input>
                        </div>
                    </div>
                    <div class="message-item">
                        <div class="message-title xin2">
                            消息内容
                        </div>
                        <div class="message-main">
                            <el-input type="textarea" v-model="input" placeholder="请输入内容"></el-input>
                        </div>
                    </div>
                    <div class="message-item">
                        <div class="message-title xin2">
                            图片
                        </div>
                        <div class="message-main">
                            <div class="cen-table upload">
                                <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card"
                                    :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                                <el-dialog :visible.sync="dialogVisible">
                                    <img width="100%" :src="dialogImageUrl" alt="">
                                </el-dialog>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nav-right">
                    <button class="btn-color" @click="handleSubmit">保存</button>
                    <button @click="handleRefund">取消</button>
                </div>
            </div>
        </div>
    </div>
</template>
        
<script>
export default {
    name: "AddAuth",
    props: ["data"],
    data() {
        return {
            radio: 3,
            users: {
                value: ""
            }
        };
    },
    mounted() {
        if (this.data.type == 'edit') {
            this.users.name = this.data.value;
        }
    },
    methods: {
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                typeText: ""
            }
            this.$emit("AddNoticeCell", obj);
        },
        handleSubmit() {
            this.handleRefund();
        },
        //编辑信息
        async userEdit() {
            let res = await this.$api.userEdit();
            if (res.code !== 1) {

            }
        },
    },
};
</script>
        
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask .mask-main {
    width: 37.917vw;
}

.table table {
    width: 37.917vw;
    margin-top: 1.852vh;
}

.table table input {
    padding-left: 1.25vw;
    font-size: 0.833vw;
}

.table {
    margin-top: 2.963vh;
}

.message-item {
    margin-bottom: 2.778vh;
}

.message-title {
    margin-bottom: 1.111vh;
}

.message-main {
    /deep/.el-input__inner {
        height: 4.907vh;
    }

    /deep/.el-textarea__inner {
        height: 23.704vh;
    }

    /deep/.el-upload--picture-card {
        width: 10.677vw;
        height: 11.111vh;
    }
}

.nav-right {
    display: flex;
    justify-content: center;
    margin-top: 4.074vh;

    button {
        width: 6.25vw;
        height: 4.259vh;
        background-color: #ffffff;
        border-radius: 0.521vw;
        border: solid 0.052vw #2c72ff;
        color: #2c72ff;
        margin-right: 2.708vw;
        cursor: pointer;
    }

    button:last-child {
        margin-right: 0;
    }

    .btn-color {
        background-color: #2c72ff;
        color: #fff;
    }
}
</style>