<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    查看附件
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <el-table class="center_table" ref="multipleTable" :data="authArr" tooltip-effect="dark"
                    style="width: 100%%" border :header-cell-style="{
                        width: 81.823 + 'vw',
                        height: 5.185 + 'vh',
                        background: '#F9F9F9',
                        fontSize: 0.729 + 'vw',
                        textAlign: 'center',

                    }" @selection-change="handleSelectionChange">
                    <el-table-column align="center" type="index" label="序号" min-width="8.5px">
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="附件名称" min-width="30.9px">
                        <template slot-scope="scope">
                            <div class="aFu_span">
                                李四拍摄问题沟通记录.jpg
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="上传时间" min-width="14.7px">
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="上传人" min-width="12.0px">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "ViewAttachments",
    props: ["data"],
    data() {
        return {
            textarea: "",
            //角色权限列表
            authArr: [
                {
                    id: 1,
                    name: "首页",
                    flag: false
                },
                {
                    id: 2,
                    name: "数据看板",
                    flag: false
                },
                {
                    id: 3,
                    name: "统计中心",
                    flag: false
                },
                {
                    id: 4,
                    name: "登记日志",
                    flag: false
                },
            ],
            multipleTable: []
        };
    },
    mounted() { },
    methods: {
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                typeText: ""
            }
            this.$emit("attaCell", obj);
        },
        handleSubmit() {
            this.handleRefund();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
    },
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask .mask-main {
    width: 37.917vw;
    box-sizing: border-box;
}

.aFu_span {
    color: #2C72FF;
    cursor: pointer;
}

/deep/.table {
    padding-top: 1.852vh;

    textarea {
        height: 33.333vh;
        resize: none;
    }
}
</style>