var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data.flag)?_c('div',{staticClass:"mask"},[_c('div',{staticClass:"mask-main"},[_c('div',{staticClass:"mask-header"},[_c('div',{staticClass:"mask-left"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),_c('i',{staticClass:"el-icon-close posi",on:{"click":_vm.handleClose}})]),_c('div',{staticClass:"mask-line"}),_c('div',{staticClass:"mask-table"},[_c('div',{staticClass:"mask-lang"},[_vm._v("服装列表")]),_c('el-table',{ref:"multipleTable",staticClass:"center_table",staticStyle:{"width":"100%"},attrs:{"max-height":"500","data":_vm.importArr,"tooltip-effect":"dark","border":"","header-cell-style":{
                    width: 72.9699 + 'vw',
                    height: 5.185 + 'vh',
                    background: '#F9F9F9',
                    fontSize: 0.833 + 'vw',
                    textAlign: 'center',

                }}},[_c('el-table-column',{attrs:{"align":"center","prop":"date_id","label":"物资ID","min-width":"18.6px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"aFu_span"},[_vm._v(_vm._s(scope.row.date_id))])]}}],null,false,3584326919)}),_c('el-table-column',{attrs:{"prop":"name","align":"center","label":"物资名称","show-overflow-tooltip":"","min-width":"16.7px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"zt","label":"库位编号","show-overflow-tooltip":"","min-width":"12.5px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"ps","label":"数量","show-overflow-tooltip":"","min-width":"9.7px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"cp","label":"物资实物","show-overflow-tooltip":"","min-width":"23.7px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"zt2","label":"物资分类","show-overflow-tooltip":"","min-width":"8.8px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"zt2","label":"适用性别","show-overflow-tooltip":"","min-width":"8.9px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"zt2","label":"创建时间","show-overflow-tooltip":"","min-width":"15.8px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"zt2","label":"创建人","show-overflow-tooltip":"","min-width":"15.8px"}})],1)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }