<template>
    <!-- 拍摄完成/拍摄未完成 -->
    <div v-if="capFlag" class="mask">
        <div class="mask-main">
            <div class="mask-header">
                <div class="mask-left">
                    服装列表
                </div>
            </div>
            <div class="mask-line"></div>
            <div class="mask-table">
                <div class="mask-lang">服装列表</div>
                <el-table class="center_table" max-height="500" ref="multipleTable" :data="importArr" tooltip-effect="dark"
                    style="width: 100%" border :header-cell-style="{
                        width: 81.823 + 'vw',
                        height: 5.185 + 'vh',
                        background: '#F9F9F9',
                        fontSize: 0.833 + 'vw',
                        textAlign: 'center',

                    }" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" min-width="4.8px" align="center">
                    </el-table-column>
                    <el-table-column align="center" prop="date_id" label="标准图库ID" min-width="13.9px">
                        <template slot-scope="scope">
                            <span class="aFu_span">{{ scope.row.date_id }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" align="center" label="标准片名称" show-overflow-tooltip
                        min-width="16.8px"></el-table-column>
                    <el-table-column align="center" prop="association" label="关联物资" show-overflow-tooltip min-width="9.6px">
                        <template slot-scope="scope">
                            <span class="aFu_span" @click="handleSub">{{ scope.row.association }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="zt" label="客户可见标准片" show-overflow-tooltip
                        min-width="29.8px"></el-table-column>
                    <el-table-column align="center" prop="ps" label="拍摄状态" show-overflow-tooltip
                        min-width="9px"></el-table-column>
                    <el-table-column align="center" prop="cp" label="是否重拍" show-overflow-tooltip
                        min-width="8.9px"></el-table-column>
                    <el-table-column align="center" prop="zt2" label="拍摄主题" show-overflow-tooltip
                        min-width="9px"></el-table-column>
                    <el-table-column align="center" prop="zt2" label="适用年龄" show-overflow-tooltip
                        min-width="14.9px"></el-table-column>
                    <el-table-column label="操作" align="center" min-width="18px">
                        <template slot-scope="scope">
                            <div class="aFu">
                                <span class="aFu_span" v-if="scope.row.id == 1">拍摄完成</span>
                                <span class="aFu_span" @click="handleReason2" v-if="scope.row.id == 1">拍摄未完成</span>
                                <span class="aFu_span" @click="handleReason4" v-if="scope.row.id == 2">重拍</span>
                                <span class="aFu_span" @click="handleReason3" v-if="scope.row.id == 3">查看原因</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="mask-foot">
                <button class="btn-color">拍摄完成</button>
                <button @click="handleReason2">拍摄未完成</button>
                <button @click="handleClose">取消</button>
            </div>
        </div>
        <!-- 拍摄未完成原因 -->
        <FailReason :negative="reason" @negativeCell2="negativeCell2"></FailReason>
        <!-- 重拍 -->
        <Substances :data="subObj" @substancesCell="substancesCell"></Substances>
    </div>
</template>

<script>
//拍摄未完成原因
import FailReason from '@/components/management/FailReason.vue';
//重拍
import Substances from '@/components/management/Substances.vue';
export default {
    name: "capture",
    props: ['capFlag'],
    data() {
        return {
            importArr: [
                {
                    id: 1,
                    date_id: "BZTK00001",
                    name: "绿叶仙踪快乐男孩",
                    association: "查看物资",
                    zt: "",
                    ps: "未拍摄",
                    cp: "否",
                    zt2: "儿童照",
                    age: "百天/满月/1周岁..."

                }, {
                    id: 2,
                    date_id: "BZTK00001",
                    name: "绿叶仙踪快乐男孩",
                    association: "查看物资",
                    zt: "",
                    ps: "未拍摄",
                    cp: "否",
                    zt2: "儿童照",
                    age: "百天/满月/1周岁..."

                }, {
                    id: 3,
                    date_id: "BZTK00001",
                    name: "绿叶仙踪快乐男孩",
                    association: "查看物资",
                    zt: "",
                    ps: "未拍摄",
                    cp: "否",
                    zt2: "儿童照",
                    age: "百天/满月/1周岁..."

                }
            ],
            multipleSelection: [],
            //查看原因
            reason: {
                negativeFlag: false,
                title: "拍摄未完成原因",
                name: "",
                btn: "保存",
                maxlength: 300,
                type: 1,//1:拍摄未完成原因 2:查看原因 3:重拍
            },
            //重拍
            subObj: {
                flag: false,
                title: "查看物资",
                name: "",
                id: ""
            }
        }
    },
    mounted() { },
    methods: {
        //关闭弹窗
        handleClose() {
            let obj = {
                capFlag: false
            }
            // console.log(obj);
            this.$emit('capCallName', obj)
        },
        //拍摄未完成原因
        handleReason2() {
            let obj = {
                negativeFlag: true,
                title: "拍摄未完成原因",
                name: "",
                btn: "保存",
                maxlength: 300,
                type: 1,//1:拍摄未完成原因 2:查看原因 3:重拍
            }
            this.reason = { ...obj }
        },
        negativeCell2(obj) {
            this.reason.negativeFlag = obj.negativeFlag;
        },
        //查看原因
        handleReason3() {
            let obj = {
                negativeFlag: true,
                title: "查看拍摄未完成原因",
                name: "",
                btn: "",
                maxlength: 300,
                type: 2,//1:拍摄未完成原因 2:查看原因 3:重拍
            }
            this.reason = { ...obj }
        },
        handleReason4() {
            let obj = {
                negativeFlag: true,
                title: "查看重拍原因",
                name: "",
                btn: "保存",
                maxlength: 300,
                type: 3,//1:拍摄未完成原因 2:查看原因 3:重拍
            }
            this.reason = { ...obj }
        },
        //重拍
        handleSub() {
            this.subObj.flag = true;
        },
        //重拍回调
        substancesCell(obj) {
            this.subObj.flag = obj.flag;
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
    },
    components: {
        FailReason,
        Substances
    }
}
</script>

<style lang="less" scoped>
@import url("@/assets/css/maskCap.less");
</style>