<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    {{ data.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <table>
                    <tr>
                        <td class="table-td">收购价格(元)</td>
                        <td colspan="3">
                            <input v-model="users.rebuy_price" type="number" placeholder="请输入收购价格">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">售卖价格(元)</td>
                        <td colspan="3">
                            <input v-model="users.price" type="number" placeholder="请输入售卖价格">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">分成比例(%)</td>
                        <td colspan="3">
                            <input v-model="rate" type="text" placeholder="分成比例【（1-收购价格/售卖价格）*100*100%】" disabled>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">所得佣金(元)</td>
                        <td colspan="3">
                            <input v-model="commission" type="number" placeholder="根据售卖价格 - 收购价格" disabled>
                        </td>
                    </tr>
                </table>
                <div class="mask-foot">
                    <button class="btn-color" @click="handleSubmit">确定</button>
                    <button @click="handleclear">重置</button>
                </div>
            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "Approved",
    props: ["data"],
    data() {
        return {
            users: {
                rebuy_price: '',//收购价格
                price: '',//售卖价格
                rate: '',//分成比例
            },
            rate: '',
            commission: null,//所得佣金
        }
    },
    created() {

    },
    methods: {
        //重置
        handleclear() {
            this.users = {
                rebuy_price: '',
                price: '',
                rate: '',
            }
            this.commission = null
        },
        handleRefund() {
            let obj = {
                flag: false,
                submitFlag: false,
            }
            this.$emit("approvedCall", obj);
        },
        //提交
        handleSubmit() {
            if (this.users.price == '' || this.users.price == null) {
                this.$message.warning("请输入售卖价格")
                return false
            }
            if (this.users.rate == '' || this.users.rate == null) {
                this.$message.warning("请输入分成比例")
                return false
            }
            if (this.users.rebuy_price > this.users.price) {
                this.$message.warning("售卖价格不得低于收购价格")
                return false
            }
            let obj = {
                flag: false,
                submitFlag: true,
                users: this.users
            }
            // console.log(obj.users);
            this.$emit("approvedCall", obj);
        },
    },
    watch: {
        data: {
            handler(newValue, oldValue) {
                this.users = {
                    rebuy_price: '',
                    price: '',
                    rate: '',
                }
                this.commission = null
            },
            deep: true
        },
        users: {
            handler(newValue, oldValue) {
                if (newValue.rebuy_price != '' && newValue.rebuy_price != null && newValue.price != '' && newValue.price != null && Number(newValue.rebuy_price) <= Number(newValue.price)) {
                    this.rate = ((1 - newValue.rebuy_price / newValue.price) * 100).toFixed(2) + '%'
                } else {
                    this.rate = null
                }
                if (newValue.price != '' && newValue.price != null) {
                    if (newValue.rate != null) {
                        this.commission = newValue.price - newValue.rebuy_price
                    } else {
                        this.commission = ''
                    }
                } else {
                    this.commission = ''
                }
            },
            deep: true
        },
        rate: {
            handler(newValue, oldValue) {
                if (newValue != null) {
                    this.users.rate = Number(newValue.replace('%', ''))
                } else {
                    this.users.rate = null
                }

            },
            deep: true
        }
    }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");



.order-mask .mask-main {
    width: 32.969vw;
}

.table table {
    width: 32.969vw;
    margin-top: 1.852vh;
}

.table table input {
    padding-left: 1.25vw;
    font-size: 0.833vw;
}

.birthpicker {
    /deep/.el-input__inner {
        border: 0 !important;
    }
}

/deep/.el-input--suffix .el-input__inner {
    padding-left: 1.25vw;
}

.cen-c {
    display: flex;
    align-items: center;
    margin-top: 3.796vh;
    font-size: 0.885vw;
    font-weight: bold;
}

.cen-c1 {
    width: 0.208vw;
    height: 1.481vh;
    background: #066FF8;
    margin-right: 0.521vw;
}

.upload {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .upload-tip {
        color: #333;
        margin-top: 2.222vh;
    }

    img {
        width: auto;
        height: auto;
        max-width: 11.719vw !important;
        max-height: 11.111vh !important;
    }
}

.flex-sw {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly !important;

    >div:first-child {
        margin-right: 1.719vw;

    }

    >div {
        text-align: center;
    }
}
</style>
<style lang="less">
.avatar-uploader .el-upload {
    border: 0.052vw dashed #d9d9d9;
    border-radius: 0.313vw;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 1.458vw;
    color: #8c939d;
    width: 13.281vw;
    height: 11.111vh;
    line-height: 11.111vh;
    text-align: center;
}

.avatar {
    width: 13.281vw;
    height: 11.111vh;
    display: block;
}

.radio-dui {
    width: 11.979vw;
    position: relative;
    line-height: 2.778vh;
    display: flex;
    align-items: center;
    margin-top: 1.574vh;

    p {
        position: absolute;
        width: 16.667vw;
        z-index: 5;
        top: -0.185vh;
        transform: translateY(-50%);

        span:first-child {
            padding-left: 1.875vw;
            cursor: pointer;
        }
    }

    input[type="radio"] {
        width: 1.042vw;
        height: 1.852vh;
        opacity: 0;
    }

    label {
        position: absolute;
        left: 0.26vw;
        top: 0.278vh;
        width: 1.042vw;
        height: 1.852vh;
        border-radius: 50%;
        border: 0.052vw solid #999;
        cursor: pointer;
    }



    /*设置选中的input的样式*/
    /* + 是兄弟选择器,获取选中后的label元素*/
    input:checked+label {
        background-color: #2c72ff;
        border: 0.052vw solid #2c72ff;
    }

    /*添加的加号与label进行拼接(一个矩形边框去掉上和左的边框),再旋转45度*/
    input:checked+label::after {
        position: absolute;
        content: "";
        width: 0.26vw;
        height: 0.926vh;
        top: 0.278vh;
        left: 0.313vw;
        border: 0.104vw solid #fff;
        border-top: none;
        border-left: none;
        transform: rotate(45deg);
    }
}
</style>