<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    {{ data.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <table>
                    <tr>
                        <td class="table-td xin2">名师姓名</td>
                        <td colspan="3">
                            <input v-model="users.name" placeholder="请输入名师姓名">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">作品热度</td>
                        <td colspan="3">
                            <input v-model="users.hot" type="number" placeholder="请输入作品热度">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">名师类型</td>
                        <td colspan="3">
                            <el-radio-group v-model="users.type">
                                <el-radio :label="item.values" v-for="item in typelist" :key="item.values">{{ item.values
                                }}</el-radio>
                                <!-- <el-radio :label="影楼">影楼</el-radio>
                                <el-radio :label="第三方">第三方</el-radio> -->
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">从业年限</td>
                        <td colspan="3">
                            <input v-model="users.workyear" type="number" placeholder="请输入从业年限">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">摄影类型</td>
                        <td colspan="3">
                            <el-select v-model="capturetype" multiple placeholder="请选择摄影类型" class="selec" clearable>
                                <el-option v-for="item in capturetypelist" :key="item.values" :label="item.values"
                                    :value="item.values">
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">摄影风格</td>
                        <td colspan="3">
                            <el-select v-model="capturestyle" multiple placeholder="请选择摄影风格" class="selec" clearable>
                                <el-option v-for="item in capturestylelist" :key="item.values" :label="item.values"
                                    :value="item.values">
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">作品风格</td>
                        <td colspan="3">
                            <el-select v-model="worksstyle" multiple placeholder="请选择作品风格" class="selec" clearable>
                                <el-option v-for="item in worksstylelist" :key="item.values" :label="item.values"
                                    :value="item.values">
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">是否上架</td>
                        <td colspan="3">
                            <div style="padding-left: 1.25vw;">
                                <el-switch v-model="users.recommend" active-color="#13ce66" inactive-color="#D7D6D6">
                                </el-switch>
                            </div>
                        </td>
                    </tr>
                </table>
                <div class="upload flex-sw">
                    <div>
                        <el-upload class="avatar-uploader" :action="uploadimg" :show-file-list="true"
                            :on-success="handleAvatarSuccess" :headers="headers" :file-list="idfilelest"
                            :before-upload="beforeAvatarUpload" :on-remove="handleRemove">
                            <img v-if="users.headimage" :src="imgurl + users.headimage" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div class="tip">
                            <div :class="['upload-tip', 'xin2']">头像</div>
                            <div class="whtip">(尺寸：宽256px&nbsp;&nbsp;高: 143.5px)</div>
                        </div>
                    </div>
                </div>
                <div class="mask-foot">
                    <button class="btn-color" @click="handleSubmit">确定</button>
                    <button @click="handleclear">重置</button>
                </div>
            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "AddTeach",
    props: ["data"],
    data() {
        return {
            idfilelest: [],
            type: null,//'add'添加 'edit'编辑
            users: {
                name: '',
                hot: null,
                workyear: null,
                capturetype: null,
                capturestyle: null,
                worksstyle: null,
                is_recommend: null,
                recommend: true,
                headimage: '',
                type: '自营'
            },
            typelist: [{
                values: '自营'
            }, {
                values: '影楼'
            }, {
                values: '第三方'
            }],
            capturetype: [],
            capturetypelist: [],//摄影类型
            capturestyle: [],
            capturestylelist: [],//摄影风格
            worksstyle: [],
            worksstylelist: [],//作品风格
            headers: {
                token: localStorage.token
            },
        }
    },
    created() {
        this.imageteacherGetcapturetype()
        this.imageteacherGetcapturestyle()
        this.imageteacherGetworksstyle()
    },
    methods: {
        //重置
        handleclear() {
            this.users = {
                name: '',
                hot: null,
                workyear: null,
                capturetype: null,
                capturestyle: null,
                worksstyle: null,
                is_recommend: null,
                recommend: true,
                headimage: '',
            }
            this.capturetype = []
            this.capturestyle = []
            this.worksstyle = []
        },
        handleRefund() {
            let obj = {
                flag: false,
                submitFlag: false,
            }
            this.$emit("addteacherCall", obj);
        },
        //提交
        handleSubmit() {
            if (this.users.name == '') {
                this.$message({
                    message: '请输入名师姓名',
                    type: 'warning'
                })
                return false
            }
            if (this.users.hot == '') {
                this.$message({
                    message: '请输入作品热度',
                    type: 'warning'
                })
                return false
            }
            if (this.users.workyear == '') {
                this.$message({
                    message: '请输入从业年限',
                    type: 'warning'
                })
                return false
            }
            if (this.capturetype.length == 0) {
                this.$message({
                    message: '请选择摄影类型',
                    type: 'warning'
                })
                return false
            }
            if (this.capturestyle.length == 0) {
                this.$message({
                    message: '请选择摄影风格',
                    type: 'warning'
                })
                return false
            }
            if (this.worksstyle.length == 0) {
                this.$message({
                    message: '请选择作品风格',
                    type: 'warning'
                })
                return false
            }
            if (this.users.headimage == '') {
                this.$message({
                    message: '请上传头像',
                    type: 'warning'
                })
                return false
            }
            let obj = {
                flag: false,
                submitFlag: true,
                users: this.users
            }
            this.$emit("addteacherCall", obj);
        },
        //图片上传成功
        handleAvatarSuccess(response, res, file) {
            if (response.code == 1) {
                this.users.headimage = response.data.imgsrc
            }
        },
        handleRemove(file, fileList) {
            this.users.headimage = ""
        },
        //图片上传前
        beforeAvatarUpload(file) {
            console.log(file);
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            return isJPG;
        },
        // 得到摄影类型
        async imageteacherGetcapturetype() {
            let res = await this.$api.imageteacherGetcapturetype()
            if (res.code == 1) {
                this.capturetypelist = res.data
            }
        },
        // 得到摄影风格
        async imageteacherGetcapturestyle() {
            let res = await this.$api.imageteacherGetcapturestyle()
            if (res.code == 1) {
                this.capturestylelist = res.data
            }
        },
        // 得到作品风格
        async imageteacherGetworksstyle() {
            let res = await this.$api.imageteacherGetworksstyle()
            if (res.code == 1) {
                this.worksstylelist = res.data
            }
        },
    },
    watch: {
        data: {
            handler(newValue, oldValue) {
                console.log(newValue.users);
                this.type = newValue.type
                if (newValue.type == 'add') {
                    this.users = {
                        name: '',
                        hot: null,
                        workyear: null,
                        capturetype: null,
                        capturestyle: null,
                        worksstyle: null,
                        is_recommend: 1,
                        recommend: true,
                        headimage: '',
                        type: '自营'
                    }
                    this.capturetype = []
                    this.capturestyle = []
                    this.worksstyle = []
                } else if (newValue.type == 'edit') {
                    this.capturetype = newValue.users.capturetype.split(',')
                    this.capturestyle = newValue.users.capturestyle.split(',')
                    this.worksstyle = newValue.users.worksstyle.split(',')
                    this.users = newValue.users
                }
            },
            deep: true
        },
        users: {
            handler(newValue, oldValue) {
                if (this.type == 'edit') {
                    newValue.is_recommend = newValue.recommend ? 1 : 0
                }
            },
            deep: true
        },
        capturetype: {
            handler(newValue, oldValue) {
                this.users.capturetype = JSON.parse(JSON.stringify(newValue)) + ''
            },
            deep: true
        },
        capturestyle: {
            handler(newValue, oldValue) {
                this.users.capturestyle = JSON.parse(JSON.stringify(newValue)) + ''
            },
            deep: true
        },
        worksstyle: {
            handler(newValue, oldValue) {
                this.users.worksstyle = JSON.parse(JSON.stringify(newValue)) + ''
            },
            deep: true
        },
    }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");



.order-mask .mask-main {
    width: 32.969vw;
}

.table table {
    width: 32.969vw;
    margin-top: 1.852vh;
}

.table table input {
    padding-left: 1.25vw;
    font-size: 0.833vw;
}

.birthpicker {
    /deep/.el-input__inner {
        border: 0 !important;
    }
}

/deep/.el-input--suffix .el-input__inner {
    padding-left: 1.25vw;
}

.cen-c {
    display: flex;
    align-items: center;
    margin-top: 3.796vh;
    font-size: 0.885vw;
    font-weight: bold;
}

.cen-c1 {
    width: 0.208vw;
    height: 1.481vh;
    background: #066FF8;
    margin-right: 0.521vw;
}

.upload {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .upload-tip {
        color: #333;
        margin-top: 2.222vh;
    }

    img {
        width: auto;
        height: auto;
        max-width: 11.719vw !important;
        max-height: 11.111vh !important;
    }
}

.flex-sw {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly !important;

    >div:first-child {
        margin-right: 1.719vw;

    }

    >div {
        text-align: center;
    }
}

.tip {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;

    .whtip {
        color: #B2AFAF;
    }
}
</style>
<style lang="less">
.avatar-uploader .el-upload {
    border: 0.052vw dashed #d9d9d9;
    border-radius: 0.313vw;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 1.458vw;
    color: #8c939d;
    width: 13.281vw;
    height: 11.111vh;
    line-height: 11.111vh;
    text-align: center;
}

.avatar {
    width: 13.281vw;
    height: 11.111vh;
    display: block;
}

.radio-dui {
    width: 11.979vw;
    position: relative;
    line-height: 2.778vh;
    display: flex;
    align-items: center;
    margin-top: 1.574vh;

    p {
        position: absolute;
        width: 16.667vw;
        z-index: 5;
        top: -0.185vh;
        transform: translateY(-50%);

        span:first-child {
            padding-left: 1.875vw;
            cursor: pointer;
        }
    }

    input[type="radio"] {
        width: 1.042vw;
        height: 1.852vh;
        opacity: 0;
    }

    label {
        position: absolute;
        left: 0.26vw;
        top: 0.278vh;
        width: 1.042vw;
        height: 1.852vh;
        border-radius: 50%;
        border: 0.052vw solid #999;
        cursor: pointer;
    }



    /*设置选中的input的样式*/
    /* + 是兄弟选择器,获取选中后的label元素*/
    input:checked+label {
        background-color: #2c72ff;
        border: 0.052vw solid #2c72ff;
    }

    /*添加的加号与label进行拼接(一个矩形边框去掉上和左的边框),再旋转45度*/
    input:checked+label::after {
        position: absolute;
        content: "";
        width: 0.26vw;
        height: 0.926vh;
        top: 0.278vh;
        left: 0.313vw;
        border: 0.104vw solid #fff;
        border-top: none;
        border-left: none;
        transform: rotate(45deg);
    }
}
</style>