<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 批量导入 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div></div>
                <div class="mask-title">
                    <!-- <div></div> -->
                    {{ data.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="mask-tip">
                <div>
                    <svg version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="1.563vw" height="2.5vh"
                        xmlns="http://www.w3.org/2000/svg">
                        <g transform="matrix(1 0 0 1 -49 -95 )">
                            <path
                                d="M 29.583255558894233 25.636083749999997  C 30.035682553624262 24.84253374609375  29.966463225776625 23.847628324218746  29.389931028106506 22.835994187500003  L 27.009438482340975 18.654693117187502  C 26.787837231878694 18.2637101953125  26.294680473372775 18.132183386718754  25.911189025517753 18.35605958203125  C 25.52624292714497 18.581446757812504  25.394225903753696 19.080032730468755  25.61742371255547 19.4704040390625  L 27.99770337925296 23.651885003906248  C 28.269578205898668 24.130540476562498  28.340216715976332 24.5565636328125  28.190956973465237 24.820336792968746  C 28.0386460220969 25.087203843749997  27.631596558339496 25.24096360546875  27.073939175758138 25  L 2.864980029585799 25  C 2.310444757766272 25.240963640625  1.905062788923816 25.08792338671875  1.7526454326923075 24.820336828125  C 1.6017536635539942 24.55346977734375  1.6739886973002955 24.121618558593752  1.950617730676775 23.634149062499997  L 14.053607167622042 2.37648575390625  C 14.605020294008874 1.4109004335937494  15.329357583672339 1.4061156679687492  15.876193948779587 2.3620595273437504  L 21.765088861409023 12.705046980468751  C 21.984951645710055 13.095346359375  22.476760193232245 13.226657343749999  22.8632673816568 13.003680515625  C 23.24817801174186 12.77829333984375  23.380230503420858 12.27967140234375  23.15706819757766 11.889372023437502  L 17.268173250277368 1.5462406406249998  C 16.13536731462648 -0.4450812539062502  13.80053615014793 -0.4419873281249998  12.661450455343937 1.560558937499999  L 0.5586383945081361 22.817862492187498  C -0.022754414755917055 23.838094757812502  -0.09346386140902371 24.8387923125  0.35899860160872804 25.636083749999997  C 0.8068842686760352 26.421431273437502  1.7197210151627218 26.872349554687503  2.8651219374075447 26.872349554687503  L 27.07393914108728 26.872349554687503  C 28.238285814996303 26.872349554687503  29.129090132673817 26.4333391875  29.583255558894233 25.636083749999997  Z "
                                fill-rule="nonzero" fill="#ffac00" stroke="none" transform="matrix(1 0 0 1 49 95 )" />
                        </g>
                    </svg>
                    <div>¡</div>
                </div>
                <div>
                    <p>导入说明:</p>
                    <p>1.文件名命名:</p>
                    <p>&emsp;&emsp; 拍摄主题#性别#衣码：例如：单个拍摄主题、衣码：儿童照#男#110；多个拍摄主题、衣码：个人写真,儿童照#男#110,120</p>
                    <p>2.注意事项：</p>
                    <p>&emsp;&emsp;2.1 文件命名严格按照命名规则进行命名，否则会导入失败；</p>
                    <p>&emsp;&emsp;2.2 拍摄主题、衣码、性别需要与系统中的名称保持一致，否则会导入失败；</p>
                    <p>&emsp;&emsp;2.3 系统中没有的拍摄主题、衣码可前往管理中心-拍摄主题添加主题，前往系统设置-数据字典-标准片衣码中添加衣码；</p>
                    <p>&emsp;&emsp;2.4 如果存在多个拍摄主题、衣码，请用【英文逗号（,）隔开】； </p>
                    <p>&emsp;&emsp;2.5 文件夹照片数量不得大于13张照片，并且不包含13张； </p>
                </div>
            </div>
            <div class="table">
                <div class="file" style="position: relative;">
                    <div id="dropzone">将文件夹拖放到此处</div>
                    <input id="file" type="file" webkitdirectory @change="handleFileUpload">
                    <el-progress class="progresslist" :text-inside="true" :stroke-width="26" percentage="0"></el-progress>
                </div>
                <div style="margin-top: 1vh;">
                    <el-table class="center_table" ref="multipleTable" height="32vh" :data="tableDatalist"
                        tooltip-effect="dark" style="width: 100%" border :header-cell-style="{
                            width: 81.823 + 'vw',
                            height: 5.185 + 'vh',
                            background: '#F9F9F9',
                            fontSize: 0.729 + 'vw',
                            textAlign: 'center',

                        }" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" min-width="4.8px" align="center"></el-table-column>
                        <el-table-column align="center" type="index" label="序号" show-overflow-tooltip
                            min-width="16.8px"></el-table-column>
                        <el-table-column align="center" prop="name" label="文件夹名称" show-overflow-tooltip
                            min-width="12px"></el-table-column>
                        <el-table-column align="center" prop="size" label="大小" show-overflow-tooltip
                            min-width="14.9px"></el-table-column>
                        <el-table-column align="center" prop="counts" label="图片数量" show-overflow-tooltip min-width="14.9px">
                        </el-table-column>
                        <el-table-column align="center" prop="status" label="状态" show-overflow-tooltip min-width="14.9px">
                            <template slot-scope="scope">
                                {{ scope.row.status == 1 ? '已完成' : '未完成' }}
                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="reason" label="原因" show-overflow-tooltip min-width="14.9px">
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 底部分页 -->
                <div class="footer-page">
                    <span>共{{ tableData.total_count }}条</span>
                    <el-pagination popper-class="paging" @size-change="handleSizeChange"
                        @current-change="handleCurrentChange" background :current-page="page" :page-sizes="[10, 20, 30, 40]"
                        :page-size="count" layout="sizes, prev, pager, next, jumper" :total="tableData.total_count"
                        :pager-count="5">
                    </el-pagination>
                </div>
                <div class="save" @click="handleSubmit">{{ data.btn }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: "GalleryImport",
    props: ["data"],
    data() {
        return {
            page: 1,
            count: 10,
            tableData: {},
            tableDatalist: [],
            textarea: "",
        };
    },
    mounted() {
    },
    methods: {
        //上传文件夹
        handleFileUpload(event) {
            var shopid = this.data.id;
            var imgurl = this.imgurl
            let files = [];
            files[0] = {}
            files[0].children = []
            var items = event.target.files;
            // const items = event.dataTransfer.items;
            console.log(items);
            for (var i = 0; i < items.length; i++) {
                if (items[i].type.indexOf('image') !== -1) {
                    files[0].children.push(items[i]);
                }

            }
            if (files.length > 0) {
                files[0].foldername = files[0].children[0].webkitRelativePath.split("/")[0]
                console.log(files);
                // this.tableDatalist.push({
                //     name: files[0].foldername,
                //     counts: files[0].children.length,
                // })
            }
            this.$nextTick(() => {
                if (files.length > 0) {
                    console.log(files);
                    const axiosInstance = axios.create({
                        baseURL: imgurl + '/api/platform',
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'token': localStorage.getItem('token')
                        },
                        onUploadProgress: function (progressEvent) {
                            const percentComplete = progressEvent.loaded / progressEvent.total * 100;
                            console.log(percentComplete);
                            if (percentComplete == 100) {
                                setTimeout(function () {
                                    document.querySelectorAll('.progresslist')[0].style.width = '0';
                                }, 500);
                            } else {
                                document.querySelectorAll('.progresslist')[0].style.width = '100%';
                                document.querySelectorAll('.el-progress-bar__inner')[0].style.width = percentComplete + '%';
                                document.querySelectorAll('.el-progress-bar__innerText')[0].innerText = percentComplete.toFixed(0) + '%';
                            }
                        }
                    });
                    axiosInstance.post('/store/import', { 'file': files, id: shopid })
                        .then(res => {
                            console.log(res);
                            // this.$message.success(res.data.msg)
                            window.alert(res.data.msg)
                            this.storeGetloglist(shopid)
                        })
                        .catch(err => {
                            this.$message.error(err.data.msg)
                        })
                }
            });
        },
        // 多文件夹上传
        handleuploads() {
            var shopid = this.data.id;
            var imgurl = this.imgurl
            var storeGetloglist = this.storeGetloglist;
            const dropzone = document.getElementById('file');

            var that = this;

            function msgtip(msg) {
                this.$message.success(msg)
            }

            dropzone.addEventListener('dragover', function (event) {
                event.preventDefault();
                dropzone.classList.add('dragover');
            });

            dropzone.addEventListener('dragleave', function (event) {
                event.preventDefault();
                dropzone.classList.remove('dragover');
            });
            let files = [];
            dropzone.addEventListener('drop', function (event) {
                event.preventDefault();
                dropzone.classList.remove('dragover');

                const items = event.dataTransfer.items;
                console.log(items);
                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    // files[i] = [];
                    files[i] = {
                        foldername: '',
                        children: []
                    };
                    if (item.kind === 'file' && item.webkitGetAsEntry().isDirectory) {
                        // console.log(item);
                        const directory = item.webkitGetAsEntry();
                        // console.log(directory);
                        // 在这里可以对每个文件夹进行处理，比如遍历文件夹中的文件
                        if (directory.isDirectory) {
                            //目录
                            const reader = directory.createReader();
                            // console.log(reader);
                            reader.readEntries(en => {
                                // console.log(en);
                                for (let entryI in en) {
                                    let entry = en[entryI]
                                    // console.log(entry);
                                    if (entry.isFile) {
                                        entry.file(
                                            file => {
                                                // 过滤非图片
                                                if (file.type.indexOf('image') !== -1) {
                                                    file.filePath = entry.fullPath.slice(1);
                                                    // files[i].push(file)


                                                    files[i].foldername = file.filePath.slice(0, file.filePath.indexOf('/'))
                                                    files[i].children.push(file)
                                                    // console.log(file);
                                                }
                                            },
                                            err => {
                                                console.log(err);
                                            }
                                        )
                                    } else {
                                        // const entryReader = entry.createReader()
                                        // entryReader.readEntries(
                                        //     (results) => {
                                        //         results.forEach(result => {
                                        //             getFilesFromEntry(result);
                                        //         })
                                        //     },
                                        //     (error) => {
                                        //         console.log(error);
                                        //     }
                                        // );
                                    }
                                }
                            })
                        } else {
                            //文件
                            directory.file((f) => {
                                console.log(f);
                            })
                        }
                    }
                }
                setTimeout(() => {
                    if (files.length > 0) {
                        console.log(files);
                        const axiosInstance = axios.create({
                            baseURL: imgurl + '/api/platform',
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'token': localStorage.getItem('token')
                            },
                            onUploadProgress: function (progressEvent) {
                                const percentComplete = progressEvent.loaded / progressEvent.total * 100;
                                console.log(percentComplete);

                                storeGetloglist(shopid)

                                if (percentComplete == 100) {
                                    setTimeout(function () {
                                        document.querySelectorAll('.progresslist')[0].style.width = '0';
                                    }, 500);
                                } else {
                                    document.querySelectorAll('.progresslist')[0].style.width = '100%';
                                    document.querySelectorAll('.el-progress-bar__inner')[0].style.width = percentComplete + '%';
                                    document.querySelectorAll('.el-progress-bar__innerText')[0].innerText = percentComplete.toFixed(0) + '%';
                                }
                            }
                        });

                        axiosInstance.post('/store/import', { 'file': files, id: shopid })
                            .then(res => {
                                console.log(res);
                                window.alert(res.data.msg)

                                that.storeGetloglist()
                            })
                            .catch(err => {

                            })
                    }

                }, 500);

            })

        },
        handleSelectionChange() {

        },
        //分页
        handleSizeChange(val) {
            //每页多少条
            this.count = val;
            this.storeGetloglist()
        },
        handleCurrentChange(val) {
            //当前多少页
            this.page = val;
            this.storeGetloglist()
        },
        // 获取店铺上传日志列表
        async storeGetloglist(id) {
            let data = {
                page: this.page,
                count: this.count,
            }
            if (id == null) {
                data.storeid = this.data.id
            } else {
                data.storeid = id
            }

            let res = await this.$api.storeGetloglist(data)
            if (res.code == 1) {
                this.tableData = res.data
                this.tableDatalist = res.data.list
            }
        },
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false
            }
            this.$emit("GalleryImportCell", obj);
        },
        handleSubmit() {
            this.handleRefund();
        },
    },
    watch: {
        data: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal.flag) {
                    this.$nextTick(() => {
                        if (newVal.id) {
                            this.handleuploads();
                            this.storeGetloglist()
                        }
                    })
                }
            }
        },
    }
}
</script>

<style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/.table {
    padding-top: 1.852vh;

    textarea {
        height: 33.333vh;
        resize: none;
    }
}

.download {
    font-size: 0.833vw;
    margin-bottom: 2.222vh;

    span {
        color: #FF0000;
    }
}

/deep/.upload-demo {
    .el-upload {
        width: 100%;

        .el-upload-dragger {
            width: 100%;
            height: 6.481vh;
            font-size: 0.833vw;
            display: flex;
            align-items: center;
            justify-content: center;

            .el-upload__text {
                font-size: 0.833vw;
                color: #666666;

                img {
                    width: 0.938vw;
                    height: 1.481vh;
                    margin-right: 0.573vw;
                }
            }

        }
    }

    .el-upload__tip {
        color: #FF0000;
    }
}
</style>
<style lang="less" scoped>
.mask-main {
    width: 60vw !important;
    // height: 85vh !important;
}

.mask-nav>div:nth-child(1),
.mask-nav>i {
    width: auto;
    height: auto;
    display: block;
}

.mask-nav>div:nth-child(2) {
    flex: 1;
    justify-content: space-around;
}


.mask-tip {
    width: calc(100%-2vw);
    height: auto;
    margin-top: 1vh;
    border-radius: 1vh;
    box-shadow: 0.104vw 0.104vw 0.104vw 0.104vw black;
    padding: 1vw 1vw;
    display: flex;
    flex-wrap: nowrap;
}

.mask-tip>div:nth-child(1) {
    width: 5%;
    height: 5%;
    text-align: center;
    position: relative;
}

.mask-tip>div:nth-child(1)>div {
    position: absolute;
    top: 0%;
    left: 45%;
    color: coral;
    font-size: 2vh;
}

.mask-tip p {
    /* height: 2vh; */
    /* font-size: 1.5vh; */
    margin: 0 !important;
    color: red;
}

.mask-tip p:nth-child(1) {
    font-size: 1.9vh !important;
}

.upload {
    width: 100%;
    height: 3.704vh;
    display: flex;
    justify-content: space-around;
    //虚线
    border: 0.052vw dashed black;

    .el-upload__text {
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;

        img {
            width: 0.938vw;
            height: 1.481vh;
        }

    }
}

.progresslist {

    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}

/deep/.el-progress-bar__outer {
    width: 100% !important;
    height: 4.63vh !important;

}

/deep/.el-progress-bar__inner {
    text-align: center !important;
}

/deep/.el-progress-bar__innerText {
    color: white !important;
    font-weight: bold !important;

}

.footer-page {
    display: flex;
    align-items: center;
    justify-content: flex-end;

}
</style>
<!-- 多文件上传 -->
<style lang="less" scoped>
.file {
    width: 100%;
    height: 4.63vh;
    position: relative;

    border: 0.052vw dashed #000;
    border-radius: 0.26vw;
}

#dropzone {
    width: 100%;
    height: 4.63vh;
    position: absolute;
    top: 0;
    z-index: 3;
    text-align: center;
    line-height: 4.63vh;
}

#file {
    width: 100%;
    height: 4.63vh;
    position: absolute;
    opacity: 0;
    top: 0;
    z-index: 3;
}
</style>