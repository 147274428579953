<template>
  <div class="order-mask" v-if="negative.negativeFlag">
    <!-- 上传底片 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <div></div>
          {{ negative.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="table">
        <el-input type="textarea" :placeholder="negative.name" v-model="textarea" :maxlength="negative.maxlength"
          show-word-limit>
        </el-input>
        <div v-if="negative.btn != ''" class="save" @click="handleSubmit">{{ negative.btn }}</div>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "FailureReason",
  props: ["negative"],
  data() {
    return {
      textarea: "",
    };
  },
  mounted() { },
  methods: {
    //取消弹窗
    handleRefund() {
      let obj = {
        negativeFlag: false
      }
      this.$emit("negativeCell", obj);
    },
    handleSubmit() {
      let obj = {
        negativeFlag: false,
        submit: true,
        typeText: this.textarea
      }
      this.$emit("negativeCell", obj);
    },
  },
  watch: {
    negative: {
      deep: true,
      handler(newVal, oldVal) {
        // console.log(newVal);
        this.textarea = newVal.textarea
      }
    }
  }
};
</script>
    
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/.table {
  padding-top: 1.852vh;

  textarea {
    height: 33.333vh;
    resize: none;
  }
}
</style>