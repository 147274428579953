<template>
    <div class="order-mask" v-if="dataVal.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    {{ dataVal.title }}
                </div>
                <i @click="handleSubmit('1')" class="el-icon-close"></i>
            </div>
            <div class="table">
                <table>
                    <tr>
                        <td class="table-td xin2">签署方式</td>
                        <td colspan="3">
                            <el-radio-group v-model="users.type" @change="handlechange(users.type)">
                                <el-radio :label="1">线上签署</el-radio>
                                <el-radio :label="2">线下签署</el-radio>
                                <el-radio :label="3">采购样片</el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">模特姓名</td>
                        <td colspan="3">
                            <input v-if="users.type == 1 || users.type == 2" type="text" v-model="users.name"
                                placeholder="请输入模特姓名">
                            <input v-if="users.type == 3" type="text" v-model="users.typename" placeholder="请输入模特姓名"
                                :disabled="disabled">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">性别</td>
                        <td colspan="3">
                            <el-select v-model="users.sex" placeholder="请选择性别" class="selec" clearable>
                                <el-option v-for="item in sex_type" :key="item.sex" :label="item.sex" :value="item.sex">
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">手机号</td>
                        <td colspan="3">
                            <input type="number" v-model="users.phone" placeholder="请输入手机号">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">身高(cm)</td>
                        <td colspan="3">
                            <input type="number" v-model="users.high" placeholder="请选择身高">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">体重(kg)</td>
                        <td colspan="3">
                            <input type="number" v-model="users.weight" placeholder="请选择体重">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">是否启用</td>
                        <td colspan="3">
                            <div style="padding-left: 1.25vw;">
                                <el-switch v-model="users.status" active-color="#13ce66" inactive-color="#D7D6D6">
                                </el-switch>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td">模特生日</td>
                        <td colspan="3">
                            <el-date-picker style="width: 100%;" class="birthpicker" v-model="users.birth" type="date"
                                placeholder="请选择模特生日">
                            </el-date-picker>

                        </td>
                    </tr>
                    <tr>
                        <td class="table-td">服务人员</td>
                        <td colspan="3">
                            <el-select v-model="users.platform_admin_id" placeholder="请选择服务人员" class="selec" clearable>
                                <el-option v-for="item in options" :key="item.id" :label="item.realname" :value="item.id">
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                </table>
                <div class="upload flex-sw">
                    <div>
                        <el-upload class="avatar-uploader" :action="uploadimg" :show-file-list="true"
                            :on-success="handleAvatarSuccess_left" :headers="headers" :file-list="idfilelest"
                            :before-upload="beforeAvatarUpload" :on-remove="handleRemove_left">
                            <img v-if="users.model_images" :src="users.model_images" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div :class="['upload-tip', 'xin2']">模特照片</div>
                        <div v-if="users.type == 3" class="radio-dui">
                            <input id="item1" type="radio" :checked="modelFlag">
                            <label for="item1"></label>
                            <p @click="handleModel"><span @click="handleModel">我已阅读并同意</span><span
                                    style="color: #2c72ff;cursor: pointer;" @click="handleTip">《服装品控用户协议》</span></p>
                        </div>
                    </div>
                    <div v-if="users.type != 3">
                        <el-upload class="avatar-uploader" :action="uploadimg" :show-file-list="true"
                            :on-success="handleAvatarSuccess_right" :headers="headers" :file-list="idfileright"
                            :before-upload="beforeAvatarUpload" :on-remove="handleRemove_right">
                            <img v-if="users.protocol_images" :src="users.protocol_images" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div :class="['upload-tip', users.type == 2 ? 'xin2' : '']">协议图片</div>
                    </div>

                    <!-- <div class="upload-tip">（图片尺寸为*******，单位像素，不超过2M，支持PNG、JPEG、JPG）</div> -->
                </div>
                <div class="mask-foot">
                    <button class="btn-color" @click="handleSubmit('2')">确定</button>
                    <button @click="handleSubmit('3')">重置</button>
                </div>
            </div>
            <TipModel :data="tipObj" @handleTipCell="handleTipCell"></TipModel>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "AddAuth",
    props: ["dataVal"],
    data() {
        return {
            disabled: false,
            headers: {
                token: localStorage.token
            },
            idfilelest: [],
            idfileright: [],
            users: {
                typename: "采购样片",
                type: 1,
                name: "",
                sex: "",
                phone: "",
                high: "",
                weight: "",
                birth: "",
                status: true,
                platform_admin_id: "",
                model_images: "",
                protocol_images: ""
            },
            modelFlag: false,
            tipObj: {
                flag: false
            },
            sex_type: [
                {
                    sex: "男"
                },
                {
                    sex: "女"
                }
            ],
            obj: {
                flag: false,
                submitFlag: true,//是否执行回调
                typeText: "",
                users: {},
            },
            options: []
        };
    },
    created() {
        this.modelerGetadmin()
    },
    methods: {
        handlechange(type) {

        },
        //用户协议弹窗
        handleTip() {
            this.tipObj.flag = true;
        },
        handleTipCell(obj) {
            this.tipObj.flag = obj.flag;
        },
        //是否同意用户协议
        handleModel() {
            this.modelFlag = !this.modelFlag;
        },
        //重置
        handleRefund() {
            let obj = {
                flag: false,
                typeText: "",
                users: this.users
            }
            this.$emit("addModelCell", obj);
        },
        //提交
        handleSubmit(type) {
            if (this.users.type == 1 || this.users.type == 2) {
                if (type == '1') {
                    let data = this.obj
                    data.submitFlag = false
                    data.users = this.users
                    this.$emit("addModelCell", data);
                } else if (type == '2') {
                    if (this.dataVal.type == 'edit') {
                        let data = this.obj
                        data.submitFlag = true
                        this.users.id = this.dataVal.id;

                        this.users.model_images = this.users.model_images.replace(imgurl, "")
                        this.users.protocol_images = this.users.protocol_images.replace(imgurl, "")
                        console.log(this.users.model_images);
                        data.users = this.users
                        this.$emit("addModelCell", data);
                        if (this.users.model_images != '') {
                            this.users.model_images = imgurl + this.users.model_images
                        }
                        if (this.users.protocol_images != '') {
                            this.users.protocol_images = imgurl + this.users.protocol_images
                        }

                    } else {
                        let data = this.obj
                        data.submitFlag = true
                        this.users.model_images = this.users.model_images.replace(imgurl, "")
                        this.users.protocol_images = this.users.protocol_images.replace(imgurl, "")
                        console.log(this.users.model_images);
                        data.users = this.users
                        this.$emit("addModelCell", data);
                        if (this.users.model_images != '') {
                            this.users.model_images = imgurl + this.users.model_images
                        }
                        if (this.users.protocol_images != '') {
                            if (this.users.protocol_images.charAt(0) == "/") {
                                this.users.protocol_images = imgurl + this.users.protocol_images
                            }
                        }

                    }
                } else if (type == '3') {
                    this.users = {
                        type: this.users.type,
                        typename: "采购样片",
                        name: "",
                        sex: "",
                        phone: "",
                        high: "",
                        weight: "",
                        birth: "",
                        status: true,
                        platform_admin_id: "",
                        model_images: "",
                        protocol_images: ""
                    }
                }
            } else if (this.users.type == 3) {
                if (this.modelFlag) {
                    if (type == '1') {
                        let data = this.obj
                        data.submitFlag = false
                        data.users = this.users
                        this.$emit("addModelCell", data);
                    } else if (type == '2') {
                        if (this.dataVal.type == 'edit') {
                            let data = this.obj
                            data.submitFlag = true
                            this.users.id = this.dataVal.id;
                            this.users.name = "采购样片"
                            data.users = this.users
                            this.$emit("addModelCell", data);
                        } else {
                            this.users.model_images = this.users.model_images.replace(imgurl, "")
                            let data = this.obj
                            data.submitFlag = true
                            data.users = this.users
                            this.$emit("addModelCell", data);
                            if (this.users.model_images != '') {
                                this.users.model_images = imgurl + this.users.model_images
                            }
                        }
                    } else if (type == '3') {
                        this.users = {
                            type: this.users.type,
                            typename: "采购样片",
                            name: "",
                            sex: "",
                            phone: "",
                            high: "",
                            weight: "",
                            birth: "",
                            status: true,
                            platform_admin_id: "",
                            model_images: "",
                            protocol_images: ""
                        }
                    }
                } else {
                    if (type == '1') {
                        let data = this.obj
                        data.submitFlag = false
                        data.users = this.users
                        this.$emit("addModelCell", data);
                    } else if (type == '3') {
                        this.users = {
                            type: "3",
                            typename: "采购样片",
                            name: "",
                            sex: "",
                            phone: "",
                            high: "",
                            weight: "",
                            birth: "",
                            status: true,
                            platform_admin_id: "",
                            model_images: "",
                            protocol_images: ""
                        }
                    } else {
                        this.$message({
                            message: "请阅读并同意《服装品控用户协议》",
                            type: 'warning'
                        });
                    }
                }
            }
        },
        //图片上传成功
        handleAvatarSuccess_left(response, res, file) {
            if (response.code == 1) {
                this.users.model_images = this.imgurl + JSON.parse(JSON.stringify(response.data.imgsrc.replace(/,/g, ""))) + ""
            }
            console.log(this.users.protocol_images)
        },
        handleRemove_left(file, fileList) {
            this.users.model_images = ""
        },
        handleAvatarSuccess_right(response, res, file) {
            if (response.code == 1) {
                this.users.protocol_images = this.imgurl + JSON.parse(JSON.stringify(response.data.imgsrc.replace(/,/g, ""))) + ""
            }
            console.log(this.users.protocol_images)
        },
        handleRemove_right(file, fileList) {
            this.users.protocol_images = ""
        },
        //图片上传前
        beforeAvatarUpload(file) {
            console.log(file);
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            return isJPG;
        },
        async modelerGetadmin() {
            let res = await this.$api.modelerGetadmin()
            this.options = res.data
        }
    },
    watch: {
        dataVal: {
            handler(newValue, oldValue) {
                if (newValue.id) {
                    let usersdata = newValue.users
                    console.log(usersdata.type);
                    if (usersdata.type == "线上签署") {
                        this.users.type = 1
                    } else if (usersdata.type == "线下签署") {
                        this.users.type = 2
                    } else if (usersdata.type == "采购样片") {
                        this.users.type = 3
                    }
                    this.users.name = usersdata.name
                    this.users.sex = usersdata.sex
                    this.users.phone = usersdata.phone
                    this.users.high = usersdata.high
                    this.users.weight = usersdata.weight
                    this.users.status = usersdata.status == 1 ? true : false
                    this.users.birth = usersdata.birth
                    if (usersdata.platform_admin_id == 0 || usersdata.platform_admin_id == null || usersdata.platform_admin_id == "") {
                        this.users.platform_admin_id = ""
                    } else {
                        this.users.platform_admin_id = usersdata.platform_admin_id
                    }
                    // this.users.platform_admin_id = usersdata.platform_admin_id
                    this.users.model_images = this.imgurl + usersdata.model_images
                    if (usersdata.protocol_images) {
                        if (usersdata.protocol_images.charAt(0) == '/') {
                            this.users.protocol_images = this.imgurl + usersdata.protocol_images
                        } else {
                            this.users.protocol_images = usersdata.protocol_images
                        }
                    }


                } else {
                    this.users = {
                        type: 1,
                        typename: "采购样片",
                        name: "",
                        sex: "",
                        phone: "",
                        high: "",
                        weight: "",
                        birth: "",
                        status: true,
                        platform_admin_id: "",
                        model_images: "",
                        protocol_images: ""
                    }
                }
            },
            deep: true
        },
        users: {
            deep: true,
            handler(newu, oldu) {

            }
        }
    }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");



.order-mask .mask-main {
    width: 32.969vw;
}

.table table {
    width: 32.969vw;
    margin-top: 1.852vh;
}

.table table input {
    padding-left: 1.25vw;
    font-size: 0.833vw;
}

.birthpicker {
    /deep/.el-input__inner {
        border: 0 !important;
    }
}

/deep/.el-input--suffix .el-input__inner {
    padding-left: 1.25vw;
}

.cen-c {
    display: flex;
    align-items: center;
    margin-top: 3.796vh;
    font-size: 0.885vw;
    font-weight: bold;
}

.cen-c1 {
    width: 0.208vw;
    height: 1.481vh;
    background: #066FF8;
    margin-right: 0.521vw;
}

.upload {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .upload-tip {
        color: #333;
        margin-top: 2.222vh;
    }

    img {
        width: auto;
        height: auto;
        max-width: 11.719vw !important;
        max-height: 11.111vh !important;
    }
}

.flex-sw {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly !important;

    >div:first-child {
        margin-right: 1.719vw;

    }

    >div {
        text-align: center;
    }
}
</style>
<style lang="less">
.avatar-uploader .el-upload {
    border: 0.052vw dashed #d9d9d9;
    border-radius: 0.313vw;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 1.458vw;
    color: #8c939d;
    width: 13.281vw;
    height: 11.111vh;
    line-height: 11.111vh;
    text-align: center;
}

.avatar {
    width: 13.281vw;
    height: 11.111vh;
    display: block;
}

.radio-dui {
    width: 11.979vw;
    position: relative;
    line-height: 2.778vh;
    display: flex;
    align-items: center;
    margin-top: 1.574vh;

    p {
        position: absolute;
        width: 16.667vw;
        z-index: 5;
        top: -0.185vh;
        transform: translateY(-50%);

        span:first-child {
            padding-left: 1.875vw;
            cursor: pointer;
        }
    }

    input[type="radio"] {
        width: 1.042vw;
        height: 1.852vh;
        opacity: 0;
    }

    label {
        position: absolute;
        left: 0.26vw;
        top: 0.278vh;
        width: 1.042vw;
        height: 1.852vh;
        border-radius: 50%;
        border: 0.052vw solid #999;
        cursor: pointer;
    }



    /*设置选中的input的样式*/
    /* + 是兄弟选择器,获取选中后的label元素*/
    input:checked+label {
        background-color: #2c72ff;
        border: 0.052vw solid #2c72ff;
    }

    /*添加的加号与label进行拼接(一个矩形边框去掉上和左的边框),再旋转45度*/
    input:checked+label::after {
        position: absolute;
        content: "";
        width: 0.26vw;
        height: 0.926vh;
        top: 0.278vh;
        left: 0.313vw;
        border: 0.104vw solid #fff;
        border-top: none;
        border-left: none;
        transform: rotate(45deg);
    }
}
</style>