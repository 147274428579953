<template>
    <div class="order-mask" v-if="data.flag">
        <div class="mask-main">
            <div class="mask-nav">
                <div></div>
                <div class="mask-title">
                    {{ data.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="mask-content">
                <div class="mask-con-item">
                    <div class="mask-con-item-head">
                        <div class="xian"></div>
                        <div class="font">顾客小程序</div>
                    </div>
                    <div class="mask-con-item-cont">
                        <img class="qrcodeimg" :src="imgurl + xcxurl" alt="">
                    </div>
                </div>
                <div class="mask-con-item">
                    <div class="mask-con-item-head">
                        <div class="xian"></div>
                        <div class="font">自定义公众号顾客关联二维码</div>
                    </div>
                    <div class="mask-con-item-cont">
                        <el-upload :action="uploadimg" list-type="picture-card" :on-remove="handleRemove"
                            :headers="headers" accept="image/jpg,image/jpeg,image/png" :file-list="imageslist"
                            :on-success="handleAvatarSuccess" :limit="limit" :on-progress="handleUploadProgress"
                            :before-upload="beforeAvatarSuccess">
                            <i slot="default" class="el-icon-plus"></i>
                            <div slot="file" slot-scope="{ file }">
                                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                                <span class="el-upload-list__item-actions">
                                    <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                                        <i class="el-icon-delete"></i>
                                    </span>
                                    <el-progress v-if="file.percentage && file.percentage != 100" type="circle"
                                        :percentage="file.percentage" :text-inside="true" />
                                </span>
                            </div>
                        </el-upload>
                    </div>
                </div>
            </div>
            <div class="mask-foot">
                <div class="footbtn1" @click="handleRefund">取消</div>
                <div class="footbtn1 footbtn2" @click="handleSubmit">保存</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BindingAccount",
    props: ["data"],
    data() {
        return {
            id: '',
            xcxurl: '',

            limit: 1,
            imageslist: [],
            urlarr: [],
            headers: {
                token: localStorage.token,
            },
            show: true
        };
    },
    mounted() {

    },
    methods: {
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                submitFlag: false
            }
            this.$emit("resershowCell", obj);
        },
        handleSubmit() {
            let obj = {
                flag: false,
                submitFlag: true,
                wxbindqrcode: this.urlarr.join(',').replace(this.imgurl, '')
            }
            this.$emit("resershowCell", obj);
        },
        handleUploadProgress(event, file, fileList) {
            this.imageslist = fileList;
            if (event.lengthComputable) {
                this.uploading = true;
                this.uploadProgress = Math.round((event.loaded * 100) / event.total);
                this.imageslist.forEach((element, i) => {
                    if (element.percentage) {
                        if (element.percentage == 100) {

                        } else {
                            this.show = false;
                            document
                                .querySelectorAll(".el-upload-list__item-actions")
                            [i].setAttribute(
                                "class",
                                "el-upload-list__item-actions percentageopacity"
                            );
                            document
                                .querySelectorAll(".el-progress")
                            [i].setAttribute("class", "el-progress");
                        }
                    } else {
                        // document
                        //     .querySelectorAll(".el-progress")
                        // [i].setAttribute("class", "el-progress percentagedisplay");
                        document
                            .querySelectorAll(".el-upload-list__item-actions")
                        [i].setAttribute("class", "el-upload-list__item-actions");
                    }
                });
            }
        },
        beforeAvatarSuccess(file) {
            var img = file.name.substring(file.name.lastIndexOf(".") + 1);
            const suffix = img === "jpg" || "JPG";
            const suffix2 = img === "png" || "PNG";
            const suffix3 = img === "jpeg" || "JPEG";
            // const isLt35M = file.size / 1024 / 1024 < 20; // 限制为小于35MB
            if (!suffix && !suffix2 && !suffix3) {
                this.$message.error("只能上传图片！");
                return false;
            }

            if (suffix || suffix2 || suffix3) {
                document.getElementsByClassName('el-upload--picture-card')[0].style.display = 'none';
            }
            return suffix || suffix2 || suffix3;
        },
        //图片上传
        handleAvatarSuccess(response, file, fileList) {
            if (response.code == 1) {
                this.show = true;
                this.urlarr.push(this.imgurl + response.data.imgsrc);
                fileList.forEach((element, i) => {
                    if (element.response && element.response.code == 0) {
                        fileList.splice(i, 1);
                    }
                });
                this.imageslist = fileList;
                for (let i = 0; i < this.urlarr.length; i++) {
                    this.imageslist[i].url = this.urlarr[i];
                    document
                        .querySelectorAll(".el-upload-list__item-actions")
                    [i].setAttribute("class", "el-upload-list__item-actions");
                    // document
                    //     .querySelectorAll(".el-progress")
                    // [i].setAttribute("class", "el-progress percentagedisplay");
                }

                this.$nextTick(() => {
                    this.imageslist.forEach((element, i) => {
                        if (element.percentage) {
                            if (element.percentage == 100) {
                                // document
                                //     .querySelectorAll(".el-progress")
                                // [i].setAttribute("class", "el-progress percentagedisplay");
                                document
                                    .querySelectorAll(".el-upload-list__item-actions")
                                [i].setAttribute("class", "el-upload-list__item-actions");
                            } else {
                                // document
                                //     .querySelectorAll(".el-progress")
                                // [i].setAttribute("class", "el-progress");
                                document
                                    .querySelectorAll(".el-upload-list__item-actions")
                                [i].setAttribute(
                                    "class",
                                    "el-upload-list__item-actions percentageopacity"
                                );
                            }
                        } else {
                            // document
                            //     .querySelectorAll(".el-progress")
                            // [i].setAttribute("class", "el-progress percentagedisplay");
                            document
                                .querySelectorAll(".el-upload-list__item-actions")
                            [i].setAttribute("class", "el-upload-list__item-actions");
                        }
                    });
                });

                this.$message({
                    type: "success",
                    message: "图片上传成功",
                });
            } else {
                fileList.forEach((element, i) => {
                    if (element.response && element.response.code == 0) {
                        fileList.splice(i, 1);
                    }
                });
                this.imageslist = fileList;
                this.$message({
                    type: "success",
                    message: response.msg,
                });
            }
        },
        //图片移除
        handleRemove(file, fileList) {
            this.imageslist = this.imageslist.filter((item) => {
                return item.url != file.url;
            });
            this.urlarr = this.urlarr.filter((item) => {
                return item != file.url;
            });
        },
    },
    created() {

    },
    watch: {
        data: {
            deep: true,
            handler(newvalue, oldvalue) {
                if (newvalue.flag) {
                    this.xcxurl = newvalue.xcxurl
                    this.id = newvalue.id

                    console.log(newvalue.wxbindqrcode)
                    if (newvalue.wxbindqrcode) {
                        this.imageslist = [{
                            url: this.imgurl + newvalue.wxbindqrcode,
                            percentage: 100
                        }]
                        this.urlarr.push(this.imgurl + newvalue.wxbindqrcode)
                    }
                }
            }
        },
        imageslist: {
            handler(newVal, oldVal) {
                this.$nextTick(() => {
                    var elements = document.querySelectorAll('.el-upload--picture-card');
                    console.log(elements)
                    if (newVal.length == 1) {
                        // document.getElementsByClassName('el-upload--picture-card')[0].style.display = 'none';

                        elements.forEach(function (element) {
                            element.style.display = 'none';
                        });
                    } else {
                        // document.getElementsByClassName('el-upload--picture-card')[0].style.display = 'block';
                        elements.forEach(function (element) {
                            element.style.display = 'block';
                        });
                    }
                })
            },
            deep: true
        }
    }
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/mask.less");

div {
    box-sizing: border-box;
}

.order-mask .mask-main {
    width: 50.052vw;
    background-color: #f9f9f9;
    border-radius: 0.521vw;
    padding: 0;

    .mask-nav {
        width: 100%;
        height: 3.646vw;
        background-color: #0f5df9;
        border-radius: 0.521vw 0.521vw 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1.042vw;

        .mask-title {
            font-size: 0.885vw;
            color: #fff;
        }

        .el-icon-close {
            color: #fff;
        }
    }

    .mask-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.667vw 2.6042vw 1.875vw 2.6042vw;

        .mask-con-item {
            width: 20.833vw;
            height: 22.917vw;

            .mask-con-item-head {
                width: 100%;
                height: 2.083vw;
                display: flex;

                .xian {
                    width: 0.208vw;
                    height: 0.833vw;
                    background-color: #2c72ff;
                    margin-right: 0.521vw;
                }

                .font {
                    font-size: 0.833vw;
                    font-weight: normal;
                    color: #333333;
                }
            }

            /deep/.mask-con-item-cont {
                width: 20.833vw;
                height: 20.833vw;
                background-color: #ffffff;
                border-radius: 0.521vw;
                display: flex;
                align-items: center;
                justify-content: center;

                .qrcodeimg {
                    min-width: 16.354vw;
                    min-height: 16.354vw;
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                    height: auto;
                }

            }
        }
    }
}

.qrcode {
    max-width: 12.76vw;
    max-height: 12.76vw;
    margin: 4.167vh auto 0;
}

.el-upload-list__item-actions:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.el-upload-list__item-delete {
    width: 3.542vw;
    height: 3.542vw;
    background-color: #f6f9ff;
    border-radius: 50%;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;

    .el-icon-delete {
        font-size: 1.4583vw;
        color: #0f5df9;
    }
}

.one {
    display: block !important;
    width: 0.781vw !important;
    height: 1.389vh !important;
    position: absolute !important;
    top: 6.481vh !important;
    left: 5.99vw !important;
    background-color: yellow !important;
    color: black !important;
    font-size: 0.625vw !important;
    line-height: 1.389vh !important;
}

.el-upload-list__item>div {
    width: 100%;
    height: 100%;
    display: flex !important;
}

.upload .el-upload-list img {
    width: auto !important;
    height: auto !important;
    max-width: 12.396vw !important;
    max-height: 17.407vh !important;
    margin: auto;
}

/deep/.percentagedisplay {
    display: none !important;
}

/deep/.el-upload-list--picture-card .percentageopacity {
    opacity: 1 !important;
}

/deep/.backcolor {
    background-color: rgba(182, 183, 183, 0.7) !important;
    opacity: 1 !important;
}

/deep/ .unlodenone {
    display: none !important;
}

/deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 16.354vw;
    height: 16.354vw;
    border-radius: 0.521vw;
}

/deep/.el-upload--picture-card {
    width: 12.5vw;
    height: 17.593vh;
    border-radius: 0.521vw;
    line-height: 17.593vh;
    border: 0;
    // display: flex;
    // align-items: center;
    // justify-content: center;

    i {
        font-size: 3.021vw;
    }
}

.mask-foot {
    width: 100%;
    height: 3.646vw;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    .footbtn1 {
        width: 6.25vw;
        height: 2.396vw;
        line-height: 2.396vw;
        text-align: center;
        font-size: 0.885vw;
        color: #2c72ff;
        background-color: #ffffff;
        border-radius: 0.521vw;
        border: solid 0.052vw #2c72ff;
        cursor: pointer;
    }

    .footbtn2 {
        background-color: #2c72ff;
        color: #ffffff;
        margin-left: 1.563vw;
    }
}
</style>