<template>
  <div class="header">
    <img src="../assets/images/head/logo2.png" class="img1" alt="">
    <div class="head-l">
      <div class="head-l1" :class="{ 'head-l1act': active == i }" v-for="(item, i) in list" :key="i"
        @click="click(i, item.router)">{{ item.name }}</div>
    </div>
    <div class="header-r">
      <div class="head-xiao">
        <img src="@/assets/images/icon/xiao.png" alt="">
      </div>
      <el-dropdown @command="command">
        <div class="header-r">
          <img src="../assets/images/head/toux.png" class="head-r" alt="">
          <div>{{ this.useInfo.realname }}</div>
          <img src="../assets/images/head/xiala.png" class="img2" alt="">
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">账号设置</el-dropdown-item>
          <el-dropdown-item command="2">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      useInfo: {},
      list: [{
        id: '1',
        name: '首页',
        router: '/'
      },
      {
        id: '2',
        name: '管理中心',
        router: '/management'
      },
      {
        id: '3',
        name: '图库管理',
        router: '/library'
      }
      ],
      active: 0
    }
  },
  created() {
    this.useInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.indexGetinfo()
  },
  mounted() {

    if (this.$route.path == "/") {
      this.active = 0;
      return false;
    }
    if (sessionStorage.getItem('activeId')) {
      this.active = Number(sessionStorage.getItem('activeId'))
    }

  },
  methods: {
    click(i, router) {
      sessionStorage.setItem('activeId', i)
      this.active = i;
      this.$router.push(router)
    },
    command(command) {
      if (command == "2") {
        this.handleExit();
      }
    },
    handleExit() {
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '退出登录成功!'
        });
        localStorage.clear();
        this.$router.push("/login")
      }).catch(() => {

      });
    },
    async indexGetinfo() {
      let res = await this.$api.indexgetInfo()
      if (res.code == 1) {
        localStorage.setItem("role_id", res.data.role_id);
        if (res.data.role_id != 1) {
          res.data.menu_ids = res.data.menu_ids != null ? res.data.menu_ids.split(",") : ['-1']
          let menuids = JSON.parse(JSON.stringify(res.data.menu_ids));
          localStorage.setItem("menu_ids", JSON.stringify(menuids));
          this.list = this.list.filter(item => {
            return res.data.menu_ids.includes(item.id)
          })
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 8.148vh;
  background-color: #fff;
  // margin-bottom: 0.926vh;
  display: flex;
  align-items: center;
  box-shadow: 0.37vh 0 0.926vh 0 #f3f2f2;

  .head-xiao {
    margin-right: 4.583vw;
    position: relative;

    img {
      width: 1.094vw;
      height: auto;
    }

  }

  .head-xiao::after {
    content: "";
    display: block;
    position: absolute;
    width: 0.365vw;
    height: 0.648vh;
    background: #FF0000;
    border-radius: 50%;
    right: 0.104vw;
    top: 0.052vw;
  }

  .head-l {
    display: flex;
  }

  .head-l1 {
    width: 7.813vw;
    text-align: center;
    font-size: 0.833vw;
    color: #333333;
    cursor: pointer;
  }

  .head-l1act {
    color: #2C72FF;
    font-weight: bold;
  }

  .head-c {
    padding: .625vw 1.354vw;
    border-radius: 1.563vw;
    color: #FF0000;
    border: 0.052vw solid #FF0000;
    margin-left: 2.604vw;
  }

  .img1 {
    width: 9.635vw;
    height: auto;
    padding: 0 1.042vw;
    box-sizing: border-box;

  }

  .head-r {
    width: 1.979vw;
    height: 1.979vw;
    margin-right: 0.521vw;
  }

  .img2 {
    width: 0.625vw;
    height: 0.365vw;
    margin-left: 0.521vw;
    margin-right: 3.646vw;
  }

  .header-r {
    margin-left: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.833vw;
  }
}
</style>
<style lang="less">
.el-dropdown-menu {
  min-width: 7.292vw;
  text-align: center;
}
</style>