<template>
    <div class="order-mask" v-if="dataval.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    {{ dataval.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="mask_cont">
                <div class="mask_l">
                    <div :class="['mask_t', activeName == item.id ? 'mask_a' : '']" v-for="item in activeList"
                        :key="item.id" @click="handleTab(item.id)">{{
                            item.name }}</div>
                </div>
                <div class="mask_r">
                    <div v-if="activeName == 0" class="table">
                        <table>
                            <tr>
                                <td class="table-td xin2">AppID</td>
                                <td colspan="3">
                                    <input type="text" v-model="users.appid" placeholder="请输入AppID">
                                </td>
                            </tr>
                            <tr>
                                <td class="table-td xin2">AppSecret</td>
                                <td colspan="3">
                                    <input type="text" v-model="users.appsecret" placeholder="请输入AppSecret">
                                </td>
                            </tr>
                            <tr>
                                <td class="table-td">二维码图片</td>
                                <td colspan="3">
                                    <div class="unloadimg">
                                        <el-upload :action="uploadimg" :headers="headers" drag :show-file-list="true"
                                            accept=".png,.jpg,.jpeg" :on-success="handleAvatarSuccess"
                                            :before-upload="beforeAvatarUpload">
                                            <img class="uploadimage" v-if="users.wxqrcode != '' && users.wxqrcode != null"
                                                :src="imgurl + users.wxqrcode" alt="">
                                            <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                                                <i class="el-icon-delete"></i>
                                            </span>
                                            <div v-if="users.wxqrcode == '' || users.wxqrcode == null"
                                                class="el-upload__text">
                                                点击或拖拽公众号二维码图片到此处上传，限上传一张
                                            </div>
                                        </el-upload>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-if="activeName == 1" class="table table2">
                        <table>
                            <tr>
                                <td class="table_td1" style="background-color: #f5f2f2;">序号</td>
                                <td class="table_td2" style="background-color: #f5f2f2;">公众号模板编号</td>
                                <td class="table_td3" style="background-color: #f5f2f2;">公众号模板标题</td>
                                <td class="table_td4" style="background-color: #f5f2f2;">公众号模板ID</td>
                            </tr>
                            <tr v-for="(item, index) in tabledatalist" :key="index">
                                <td class="table_td1">{{ index + 1 }}</td>
                                <td class="table_td2">{{ item.bianhao }}</td>
                                <td class="table_td3">{{ item.name }}</td>
                                <td class="table_td4">
                                    <el-input v-model="item.template_id" placeholder="请输入公众号模板ID"></el-input>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="table_f">
                <div v-if="dataval.btn1 != '' && show" class="table_f_b" @click="handleRefund">{{ dataval.btn1
                }}</div>
                <div v-if="dataval.btn2 != '' && show && activeName == 0" class="table_f_b save" @click="handleSubmit1">{{
                    dataval.btn2
                }}</div>
                <div v-if="dataval.btn2 != '' && show && activeName == 1" class="table_f_b save" @click="handleSubmit2">{{
                    dataval.btn2
                }}</div>
            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "allocation",
    props: ["dataval"],
    data() {
        return {
            id: '',
            users: {
                id: '',
                appid: "",
                appsecret: "",
                wxqrcode: '',
            },
            activeName: 0,
            activeList: [{
                id: 0,
                name: "基本参数",
            }, {
                id: 1,
                name: "消息模板",
            }],
            headers: {
                token: localStorage.token
            },
            show: true,

            tabledatalist: []
        };
    },
    mounted() {

    },
    methods: {
        handleTab(id) {
            this.activeName = id;
        },
        handleAvatarSuccess(response, res, file) {
            if (response.code == 1) {
                this.users.wxqrcode = response.data.imgsrc;
                this.show = true;
                console.log(this.users.wxqrcode);
            }
        },
        beforeAvatarUpload(file) {
            var img = file.name.substring(file.name.lastIndexOf('.') + 1)
            const suffix = img === 'jpg'
            const suffix2 = img === 'png'
            const suffix3 = img === 'jpeg'
            if (!suffix && !suffix2 && !suffix3) {
                this.$message.error("只能上传图片！");
                return false
            }
            const isLt2M = file.size / 1024 / 1024 < 20;
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
                return false
            }
            this.show = false;
            return suffix || suffix2 || suffix3
        },
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                submitFlag: false
            }
            this.$emit("allocationCall", obj);
        },
        async handleSubmit1() {
            let res = await this.$api.storeWxset(this.users)
            if (res.code == 1) {
                this.storeDetail()
                this.$message.success('设置成功')
            }
        },
        async handleSubmit2() {
            let res = await this.$api.storeTempset({
                store_id: this.id,
                info: this.tabledatalist
            })
            if (res.code == 1) {
                this.storeTempget()
                this.$message.success('设置成功')
            }
        },
        async storeDetail() {
            let res = await this.$api.storeDetail({ id: this.id });
            if (res.code == 1) {
                this.users.appid = (res.data.appid == '' || res.data.appid == null) ? '' : res.data.appid
                this.users.appsecret = (res.data.appsecret == '' || res.data.appsecret == null) ? '' : res.data.appsecret
                this.users.wxqrcode = (res.data.wxqrcode == '' || res.data.wxqrcode == null) ? '' : res.data.wxqrcode
            }
        },
        async storeTempget() {
            let res = await this.$api.storeTempget({ store_id: this.id });
            if (res.code == 1) {
                this.tabledatalist = res.data
                this.tabledatalist.forEach(item => {
                    if (item.template_id == null) {
                        item.template_id = ''
                    }
                })
            }
        }
    },
    watch: {
        dataval: {
            deep: true,
            handler(newvalue, oldvalue) {
                this.activeName = 0
                this.id = newvalue.id
                this.users.id = newvalue.id
                this.storeDetail()
                this.storeTempget()
            }
        },
        tabledatalist: {
            deep: true,
            handler(newvalue, oldvalue) {
                console.log(newvalue);
            }
        }
    }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask .mask-main {
    // width: 32.969vw;
    width: auto;
    padding: 0;
}

/deep/.el-upload {
    display: flex;
    justify-content: center;
    align-items: center;

    .el-upload-dragger {
        width: 100%;
        height: 10.938vw;
        border: 0;
        border-radius: .3125vw;
        // background-color: #f2f2f2;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .el-upload__text {
            font-size: .8333vw;
            color: #b2afaf;

        }
    }
}

/deep/.el-upload-list {
    display: none;
}

.order-mask .mask-main .mask-nav {
    height: 3.125vw;
    display: flex;
    align-items: center;
    padding: 0 1.3021vw;
}

.mask_cont {
    display: flex;
    justify-content: space-between;
    background-color: #eceaea;
    padding-top: .2604vw;
    padding-bottom: .5208vw;

    .mask_l {
        width: 7.8125vw;
        background-color: #fff;
        margin-right: .5208vw;

        .mask_t {
            width: 100%;
            height: 2.6042vw;
            line-height: 2.6042vw;
            text-align: center;
            cursor: pointer;
        }

        .mask_a {
            color: #fff;
            background-color: #0072f9;
        }
    }

    .mask_r {
        width: 54.8958vw;
        height: 32.0313vw;
        background-color: #fff;
        padding: .5208vw .7813vw;

        .table {
            table {
                width: 32.969vw;
                margin-top: 1.852vh;

                .table-td {
                    background-color: #f2f2f2;
                }

                input {
                    padding-left: 1.25vw;
                    font-size: 0.833vw;
                }
            }
        }

        .table2 {
            width: 100%;
            height: 32.0313vw;

            table {
                height: auto;
                max-height: 32.0313vw;
                overflow: scroll;
                margin: 0;

                tr {
                    width: 100%;
                    display: flex;

                    td {
                        height: 2.0833vw;
                        line-height: 2.0833vw;
                        text-align: center;
                        padding: .0521vw;
                    }

                    .table_td1 {
                        width: 3.3854vw;
                    }

                    .table_td2 {
                        width: 7.2917vw;
                    }

                    .table_td3 {
                        width: 9.375vw;
                    }

                    .table_td4 {
                        flex: 1;

                        /deep/.el-input {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            box-sizing: border-box;
                            padding: .2083vw .1042vw .2083vw .2083vw;

                            .el-input__inner {
                                width: 100%;
                                height: 100%;
                                border-radius: .2083vw;
                                border: .0521vw solid #DCDFE6;
                            }
                        }
                    }
                }
            }
        }
    }
}

.table_f {
    width: 100%;
    height: 3.3854vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .table_f_b {
        width: 6.25vw;
        height: 2.396vw;
        line-height: 2.396vw;
        text-align: center;
        font-family: MicrosoftYaHei-Bold;
        font-size: 0.729vw;
        color: #2c72ff;
        background-color: #ffffff;
        border-radius: 0.521vw;
        border: solid 0.052vw #2c72ff;
        margin-right: 1.6667vw;
        cursor: pointer;
    }

    .save {
        background-color: #2c72ff;
        color: #ffffff;
        margin-right: 1.25vw;
    }
}
</style>