<template>
  <div class="order-mask" v-if="dataVal.flag">
    <!-- 添加订单 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div></div>
        <div class="mask-title">ERP对接</div>
        <i class="el-icon-close" @click="handleRefund"></i>
      </div>
      <div class="mask-content">
        <div class="cenbox">
          <div class="cen">
            <div class="cen_head">
              <div class="cen_xian"></div>
              <div class="cen_title xin2">ERP类型</div>
            </div>
            <div class="cen_body">
              <el-radio-group v-model="userobj.type" @input="handleInput">
                <el-radio
                  :label="item.id"
                  v-for="item in storetypelist"
                  :key="item.id"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="cenbox">
          <div class="cen">
            <div class="cen_head">
              <div class="cen_xian"></div>
              <div class="cen_title xin2">对接版本</div>
            </div>
            <div class="cen_body">
              <el-radio-group v-model="userobj.version">
                <el-radio
                  :label="item.id"
                  v-for="item in storetypelist2"
                  :key="item.id"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="cenbox">
          <div class="cen">
            <div class="cen_head">
              <div class="cen_xian"></div>
              <div class="cen_title xin2">主机名</div>
            </div>
            <div class="cen_body">
              <el-input
                v-model="userobj.hostname"
                placeholder="请输入主机名"
              ></el-input>
            </div>
          </div>
          <div class="cen">
            <div class="cen_head">
              <div class="cen_xian"></div>
              <div class="cen_title xin2">数据库名</div>
            </div>
            <div class="cen_body">
              <el-input
                v-model="userobj.databasename"
                placeholder="请输入数据库名"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="cenbox">
          <div class="cen">
            <div class="cen_head">
              <div class="cen_xian"></div>
              <div class="cen_title xin2">用户名</div>
            </div>
            <div class="cen_body">
              <el-input
                v-model="userobj.username"
                placeholder="请输入用户名"
              ></el-input>
            </div>
          </div>
          <div class="cen">
            <div class="cen_head">
              <div class="cen_xian"></div>
              <div class="cen_title xin2">密码</div>
            </div>
            <div class="cen_body">
              <el-input
                v-model="userobj.password"
                placeholder="请输入密码"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="cenbox">
          <div class="cen">
            <div class="cen_head">
              <div class="cen_xian"></div>
              <div class="cen_title xin2">数据库端口</div>
            </div>
            <div class="cen_body">
              <el-input
                v-model="userobj.port"
                placeholder="请输入数据库端口"
              ></el-input>
            </div>
          </div>
          <div class="cen">
            <div class="cen_head">
              <div class="cen_xian"></div>
              <div class="cen_title">商家唯一标识值</div>
            </div>
            <div class="cen_body">
              <el-input
                v-model="userobj.fieldvalue"
                placeholder="请输入商家唯一标识值"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="mask-footer">
        <button @click="handleRefund">{{ dataVal.btns }}</button>
        <button class="btn-bg" @click="handleSubmit">{{ dataVal.btnq }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErpIntegration",
  props: ["dataVal"],
  data() {
    return {
      userobj: {
        store_id: "", // 商家id
        type: "", // ERP类型
        hostname: "", // 主机名
        databasename: "", // 数据库名
        username: "", // 用户名
        password: "", // 密码
        fieldvalue: "", // 商家唯一标识值
        port: "", //数据库端口
        version: "", //对接版本
      },
      storetypelist: [
        {
          id: 1,
          name: "素玄ERP",
        },
        {
          id: 2,
          name: "利亚方舟",
        },
        {
          id: 5,
          name: "智诚",
        },
      ], // :1=素玄ERP,2=利亚方舟,3=樱桃互动,4=博城,5=智诚,6=恒友
      storetypelist2: [
        {
          id: 1,
          name: "标准版",
        },
        {
          id: 2,
          name: "东力士版",
        },
      ], // 1=标准版,2=东力士版
    };
  },
  mounted() {},
  methods: {
    handleInput(id) {
      //   console.log(id);
      if (id == 1) {
        this.storetypelist2 = [
          {
            id: 1,
            name: "标准版",
          },
          {
            id: 2,
            name: "东力士版",
          },
        ];
      } else {
        this.storetypelist2 = [
          {
            id: 1,
            name: "标准版",
          },
        ];
      }
      this.userobj.version = this.storetypelist2[0].id;
    },
    //取消弹窗
    handleRefund() {
      let data = {
        flag: false,
        submitFlag: false,
      };
      this.$emit("integrationCall", data);
    },
    handleSubmit() {
      if (this.userobj.type == "") {
        this.$message.error("请选择ERP类型");
        return;
      }
      if (this.userobj.version == "") {
        this.$message.error("请选择对接版本");
        return;
      }
      if (this.userobj.hostname == "") {
        this.$message.error("请输入主机名");
        return;
      }
      if (this.userobj.databasename == "") {
        this.$message.error("请输入数据库名");
        return;
      }
      if (this.userobj.username == "") {
        this.$message.error("请输入用户名");
        return;
      }
      if (this.userobj.password == "") {
        this.$message.error("请输入密码");
        return;
      }
      if (this.userobj.port == "") {
        this.$message.error("请输入数据库端口");
        return;
      }
      let data = {
        flag: false, //弹窗是否显示
        submitFlag: true, //是否调用接口
        obj: this.userobj,
      };
      this.$emit("integrationCall", data);
    },
  },
  watch: {
    dataVal: {
      handler(newValue, oldValue) {
        if (newValue.flag) {
          this.userobj = newValue.data;
          let id = this.userobj.type;
          if (id) {
            if (id == 1) {
              this.storetypelist2 = [
                {
                  id: 1,
                  name: "标准版",
                },
                {
                  id: 2,
                  name: "东力士版",
                },
              ];
            } else {
              this.storetypelist2 = [
                {
                  id: 1,
                  name: "标准版",
                },
              ];
            }
            this.userobj.version = this.storetypelist2[0].id;
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/mask.less");

div {
  box-sizing: border-box;
}

.order-mask {
  .mask-main {
    width: 40vw;
    padding: 0;
    border-radius: 1.296vh 0.521vw 0.926vh 0.521vw;

    .mask-nav {
      height: 2.6042vw;
      padding: 0 0.7813vw;
      background-color: #066ff8;

      .mask-title {
        font-weight: 400;
        color: #fff;
      }

      .el-icon-close {
        color: #fff;
        display: flex;
        align-items: center;
      }
    }
  }

  .mask-content {
    width: 100%;
    background-color: #fff;
    padding: 0 0.7813vw;

    .cenbox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .cen {
        flex: 1;

        .cen_head {
          display: flex;
          align-items: center;
          padding: 0.5208vw 0;

          .cen_xian {
            margin-right: 0.521vw;
            width: 0.208vw;
            height: 0.833vw;
            background-color: #2c72ff;
          }

          .xin2::after {
            content: "*";
            color: red;
          }
        }

        /deep/.cen_body {
          width: 100%;
          height: 1.8229vw;
          border: 0.0521vw solid #d7d7d7;
          border-radius: 0.2604vw;
          display: flex;
          align-items: center;

          .el-radio-group {
            text-align: left;
            padding-left: 0.7813vw;
          }

          .el-input {
            .el-input__inner {
              border: 0;
            }
          }
        }
      }

      .cen:nth-of-type(2) {
        margin-left: 0.7813vw;
      }
    }
  }

  .mask-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5625vw;
    margin-bottom: 0.7813vw;

    button {
      width: 7.813vw;
      height: 4.259vh;
      border-radius: 0.313vw;
      font-size: 0.729vw;
      cursor: pointer;
    }

    button {
      background: #fff;
      border: 0.052vw solid #066ff8;
      color: #066ff8;
      margin-right: 3.125vw;
    }

    button:last-child {
      margin-right: 0;
    }

    .btn-bg {
      background: #066ff8;
      border: 0.052vw solid #066ff8;
      color: #fff;
    }
  }
}
</style>