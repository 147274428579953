<template>
    <div class="order-mask" v-if="dataVal.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    {{ dataVal.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <table>
                    <tr>
                        <td class="table-td xin2">商品主题</td>
                        <td colspan="3">
                            <input type="text" v-model="user.name" placeholder="请输入商品主题">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">是否启用</td>
                        <td colspan="3">
                            <div style="padding-left: 1.25vw;">
                                <el-switch v-model="status" active-color="#13ce66" inactive-color="#D7D6D6">
                                </el-switch>
                            </div>
                        </td>
                    </tr>
                </table>
                <div v-if="dataVal.btn != ''" class="save" @click="handleSubmit">{{ dataVal.btn }}</div>
            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "AddAuth",
    props: ["dataVal"],
    data() {
        return {
            status: true,
            user: {
                name: "",
                status: 1
            },
        };
    },
    mounted() {

    },
    methods: {
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                submitFlag: false
            }
            this.$emit("addAccountCell", obj);
        },
        handleSubmit() {
            let obj = {
                flag: false,
                submitFlag: true,
                typeText: "",
                user: this.user,
            }
            this.$emit("addAccountCell", obj);
        },
    },
    watch: {
        dataVal: {
            handler(newVal, oldVal) {
                this.user = JSON.parse(JSON.stringify(newVal.user))
                this.status = JSON.parse(JSON.stringify(newVal.user.status))
            },
            deep: true
        },
        status: {
            handler(newVal, oldVal) {
                this.user.status = newVal ? 1 : 0
            },
            deep: true
        }
    }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask .mask-main {
    width: 32.969vw;
}

.table table {
    width: 32.969vw;
    margin-top: 1.852vh;
}

.table table input {
    padding-left: 1.25vw;
    font-size: 0.833vw;
}

/deep/ .el-input--suffix .el-input__inner {
    padding-left: 1.25vw;
}
</style>