<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 批量导入 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    {{ data.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <div class="download">请先<span class="downloadspan" @click="downloadExcel()">下载批量导入模板</span></div>
                <el-upload class="upload-demo" :show-file-list="true" :before-upload="beforeAvatarUpload" drag
                    :headers="headers" :on-success="handleSuccess" :on-remove="handleRemove" :action="uploadfile" multiple>
                    <div class="el-upload__text">
                        <img src="@/assets/images/icon/import.png" alt="">
                        将文件拖到此处或点击上传
                    </div>
                    <div class="el-upload__tip" slot="tip"><span class="file">{{ result }}</span></div>
                </el-upload>
                <div class="save" @click="handleSubmit">{{ data.btn }}</div>
            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "BatchImport",
    props: ["data"],
    data() {
        return {
            textarea: "",
            result: "",
            headers: {
                token: localStorage.getItem('token')
            },
            file: "",
            fileLists: [],
            datasheet: [],//数据
        };
    },
    mounted() {
        console.log(localStorage.getItem('token'))
    },
    methods: {

        downloadExcel() {
            let a = document.createElement("a");
            if (this.data.title == "批量导入预约单") {
                a.href = this.imgurl + "/uploads/excel/1691222618993847.xlsx"
            } else if (this.data.title == "批量导入库位单") {
                a.href = this.imgurl + "/uploads/excel/1691223722495833.xlsx"
            } else if (this.data.title == "批量导入拍摄主题") {
                a.href = this.imgurl + "/uploads/excel/1691222687382957.xlsx"
            } else if (this.data.title == "批量导入道具链接") {
                a.href = this.imgurl + "/uploads/excel/proplink.xlsx"
            } else if (this.data.title == "批量导入商品主题") {
                a.href = this.imgurl + "/uploads/excel/imagetheme.xlsx"
            } else {
                this.$message({
                    type: "error",
                    message: "暂无导入模块"
                })
            }
            document.body.appendChild(a);
            a.click();
            a.remove();
        },
        hanlechange(file, fileList) {
            console.log(URL.createObjectURL(file.raw))
        },
        handleSuccess(response, file, fileList) {
            if (response.code == 1) {
                this.file = response.data.file
                this.fileLists.push(response.data.file)
            }

        },
        handleRemove(file, fileList) {
            this.fileLists = this.fileLists.filter((item => {
                return item != file.response.data.file
            }))
        },
        beforeAvatarUpload(file) {
            const isJPG = file.name.slice(-5) === '.xlsx'
            if (!isJPG) {
                this.$message({
                    message: '上传文件只能是 JAR 格式',
                    type: 'error'
                })
            }

            return isJPG
        },
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                flags: false
            }
            this.$emit("BatchImportCell", obj);
        },
        handleSubmit() {
            let obj = {
                flag: false,
                flags: true,
                data: this.fileLists
            }
            this.$emit("BatchImportCell", obj);
            this.file = []
            this.fileLists = []
        },
    },
    watch: {
        data: {
            deep: true,
            handler(newvalue, oldvalue) {
                // if (newvalue.title == "批量导入预约单") {
                //     let data
                //     this.unlodeurl = "https://jiekou.paiyide.cc/api/store/order/import"
                // } else if (newvalue.title == "批量导入库位单") {
                //     this.unlodeurl = "https://jiekou.paiyide.cc/api/store/locator/import"
                // } else if (newvalue.title == "批量导入拍摄主题") {
                //     this.unlodeurl = "https://jiekou.paiyide.cc/api/store/theme/import"
                // }
            }
        }

    }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/.table {
    padding-top: 1.852vh;

    textarea {
        height: 33.333vh;
        resize: none;
    }
}

.download {
    font-size: 0.833vw;
    margin-bottom: 2.222vh;

    span {
        color: #FF0000;
    }
}


/deep/.upload-demo {
    .el-upload {
        width: 100%;

        .el-upload-dragger {
            width: 100%;
            height: 6.481vh;
            font-size: 0.833vw;
            display: flex;
            align-items: center;
            justify-content: center;

            .el-upload__text {
                font-size: 0.833vw;
                color: #666666;

                img {
                    width: 0.938vw;
                    height: 1.481vh;
                    margin-right: 0.573vw;
                }
            }

        }


    }

    .el-upload__tip {
        color: #FF0000;
    }
}
</style>