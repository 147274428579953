<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 预约信息查看 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    {{ data.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table filei">
                <div class="cen-table cloth">
                    <div class="cloth-item" v-for="item in data.themejson" :key="item.id">
                        <div class="cloth-name">{{ item.name }}</div>
                        <div class="cloth-num">{{ item.count }}</div>
                        <div class="cloth-foot">拍摄数量</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "MeservationCell",
    props: ["data"],
    data() {
        return {
            textarea: "",
        };
    },
    mounted() {

    },
    methods: {
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false
            }
            this.$emit("MeservationCell", obj);
        },
        handleSubmit() {
            this.handleRefund();
        },
    },
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");
@import url("@/assets/css/table.less");

.mask-main {
    min-height: 50.926vh;

    .cen-table {
        margin-left: 0;

    }
}

.filei .cloth-item:nth-child(3n) {
    margin-right: 0;
}
</style>