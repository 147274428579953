<template>
    <div class="imgpreview" v-if="data.flag" @contextmenu="disableRightClick">
        <img :src="src" alt="">
        <div class="watermark">{{ data.shopname ? data.shopname : '慕梓科技' }}</div>
        <div class="cancellation" @click="handleRefund">
            <i class="el-icon-close"></i>
        </div>
    </div>
</template>
<script>
export default {
    name: "MaskPack",
    props: ['data'],
    data() {
        return {
            src: ''
        };
    },
    mounted() {
    },
    methods: {
        // 阻止默认右键菜单的显示
        disableRightClick(event) {
            event.preventDefault();
            return false
        },
        //取消弹窗
        handleRefund() {
            let data = {
                flag: false,
                submitFlag: false
            }
            this.$emit("imgpreviewCall", data);
        },
    },
    watch: {
        data: {
            handler(newVal, oldVal) {
                if (newVal.flag) {
                    this.src = newVal.src
                }
            },
            deep: true
        }
    }
};
</script>
<style lang="less" scoped>
.imgpreview {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    // position: relative;

    img {
        max-width: 100vw;
        max-height: 100vh;
        width: auto;
        height: auto;
    }

    .watermark {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.3;
        color: #fff;
        font-size: 1.6667vw;
        font-weight: bold;
        cursor: default;
    }

    .cancellation {
        position: absolute;
        top: 2.0833vw;
        right: 2.0833vw;
        width: 2.0833vw;
        height: 2.0833vw;
        background-color: #606266;
        color: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .el-icon-close {
            font-weight: 400;
            font-size: 1.25vw;
            color: #fff;
        }

    }

}
</style>