<template>
    <!-- 拍摄完成/拍摄未完成 -->
    <div v-if="data.flag" class="mask">
        <div class="mask-main">
            <div class="mask-header">
                <div class="mask-left">
                    服装列表
                </div>
            </div>
            <div class="mask-line"></div>
            <div class="mask-table">
                <div class="btn-foot" v-if="data.type == 'see'">
                    <button class="btn-color" @click="handleSupplies('add')">关联物资</button>
                    <button>批量移除</button>
                </div>
                <div v-if="data.type == 'add'" class="search_nav">
                    <!-- 搜索框 -->
                    <el-input v-model="search_input" class="search_inp" style="width: 13.542vw;"
                        placeholder="标准片ID/标准片名称"></el-input>
                    <!-- 衣码下拉框 -->
                    <el-select v-model="value" placeholder="请选择拍摄主题" class="clothing_size" clearable>
                        <el-option v-for="item in clothing_size" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <!-- 评价状态下拉框 -->
                    <el-select v-model="value" placeholder="请选择风格分类" class="evaluation_status" clearable>
                        <el-option v-for="item in evaluation_status" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>

                    <div class="block" style="margin-right: 0.521vw;">
                        <div class="yards">
                            <el-input v-model="min_size" style="width: 4.167vw;" placeholder="衣码最小码"></el-input>
                            <span>-</span>
                            <el-input v-model="max_size" style="width: 4.167vw;" placeholder="衣码最大码"></el-input>
                        </div>
                    </div>
                    <el-select v-model="value" placeholder="请选择标准片来源" class="evaluation_status" clearable>
                        <el-option v-for="item in evaluation_status" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <!-- 查询按钮 -->
                    <el-button class="searchBtn" type="primary">查询</el-button>
                </div>
                <el-table id="filei" class="center_table" max-height="500" ref="multipleTable" :data="importArr"
                    tooltip-effect="dark" style="width: 100%" border :header-cell-style="{
                        width: 81.823 + 'vw',
                        height: 5.185 + 'vh',
                        background: '#F9F9F9',
                        fontSize: 0.833 + 'vw',
                        textAlign: 'center',

                    }" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" min-width="4.8px" align="center">
                    </el-table-column>
                    <el-table-column align="center" prop="date_id" label="标准图库ID" min-width="12.6px">
                        <template slot-scope="scope">
                            <span class="aFu_span">{{ scope.row.date_id }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" align="center" label="标准片名称" show-overflow-tooltip
                        min-width="16.5px"></el-table-column>
                    <el-table-column align="center" prop="association" label="客户可见标准片" show-overflow-tooltip
                        min-width="29.6px">
                    </el-table-column>
                    <el-table-column align="center" prop="zt" label="拍摄主题" show-overflow-tooltip
                        min-width="14.7px"></el-table-column>
                    <el-table-column align="center" prop="ps" label="适用年龄" show-overflow-tooltip
                        min-width="10.3px"></el-table-column>
                    <el-table-column align="center" prop="cp" label="衣码" show-overflow-tooltip
                        min-width="11.7px"></el-table-column>
                    <el-table-column label="标准片来源" prop="zt2" align="center" min-width="12.0px">
                    </el-table-column>
                </el-table>
            </div>
            <div class="mask-foot">
                <button @click="handleClose" v-if="data.type == 'see'" class="btn-color">保存</button>
                <button @click="handleClose" v-else class="btn-color">确认关联</button>
                <button @click="handleClose">取消</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "capture",
    props: ['data'],
    data() {
        return {
            importArr: [
                {
                    id: 1,
                    date_id: "BZTK00001",
                    name: "绿叶仙踪快乐男孩",
                    association: "查看物资",
                    zt: "",
                    ps: "未拍摄",
                    cp: "否",
                    zt2: "儿童照",
                    age: "百天/满月/1周岁..."

                }, {
                    id: 2,
                    date_id: "BZTK00001",
                    name: "绿叶仙踪快乐男孩",
                    association: "查看物资",
                    zt: "",
                    ps: "未拍摄",
                    cp: "否",
                    zt2: "儿童照",
                    age: "百天/满月/1周岁..."

                }, {
                    id: 3,
                    date_id: "BZTK00001",
                    name: "绿叶仙踪快乐男孩",
                    association: "查看物资",
                    zt: "",
                    ps: "未拍摄",
                    cp: "否",
                    zt2: "儿童照",
                    age: "百天/满月/1周岁..."

                }
            ],
            multipleSelection: [],
        }
    },
    mounted() { },
    methods: {
        handleSupplies(type) {
            this.data.type = type;
        },
        //关闭弹窗
        handleClose() {
            let obj = {
                flag: false
            }
            // console.log(obj);
            this.$emit('handleSuppliesCell', obj)
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
    }
}
</script>

<style lang="less" scoped>
@import url("@/assets/css/maskCap.less");
@import url("@/assets/css/reservation/telement.less");

.filei .btn-foot {
    justify-content: flex-end;
    margin-top: 0;
    margin-bottom: 2.222vh;
}

.search_nav {
    padding: 0 0 2.222vh;
}

.search_nav .search_inp {
    margin-left: 0;
}

.btn-foot {
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
    margin-bottom: 2.222vh;

    button {
        width: 6.25vw;
        height: 4.259vh;
        background-color: #ffffff;
        border-radius: 0.521vw;
        border: solid 0.052vw #2c72ff;
        color: #2c72ff;
        margin-right: 1.563vw;
        cursor: pointer;
    }

    button:last-child {
        margin-right: 0;
    }

    .btn-color {
        background-color: #2c72ff;
        color: #fff;
    }
}

.yards {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 11.979vw;
    padding: 0 1.094vw;
    border: 0.052vw solid #DCDFE6;
    border-radius: 0.208vw;
    height: 3.75vh;
    margin-right: 0.521vw;
    box-sizing: border-box;
    margin-top: -0.052vw;

    span {
        color: #999999;
        margin: 0 0.521vw;
    }

    /deep/.el-input__inner {
        border: none;
        padding: 0;
        height: 3.333vh;
        box-sizing: border-box;
    }
}
</style>